import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

const BlogTwo = () => {
    const location = useLocation();

    useEffect(() => {
        const hash = location?.hash;
        if (hash) {
            const section = document.querySelector(hash);
            section?.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    });

    return (
        <>
            <div className="blog-mainsection">
                <img className="blog-blogbgi" src="../assets/images/blogbgi.png" alt="" />
                <img className="blog-blogbgi2" src="../assets/images/blogbgi2.png" alt="" />
                <div className="container">
                    <div className="blogtitle-section">
                        <h2 className="blog-title">An Introduction to Web Assembly</h2>
                        <NavLink className="blog-back" to={'/'}> <img className="blog-backim" src="../assets/images/left-arrow-blog.svg" alt="" /></NavLink>
                    </div>
                    <div className="blog-bgim">
                        <img className="" src="../assets/images/blog/blog2-1.png" alt="" />
                    </div>
                </div>

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-9">
                            <div className="blogcontent-section">
                                <h6 className="blog-subhead">Introduction</h6>
                                <p className="blog-subtxt">Today, web browsers are an indispensable part of our day-to-day lives, and they are one of the easiest and most convenient ways for us to look something up on the internet. Over the years, web browsers have gotten powerful, but they are still imperfect as current web browsers are still incapable of running several services and applications. But why is it so that even after several decades of evolution, web browsers are still incapable of performing complex applications like video rendering or working with massive amounts of data? </p>
                                <p className="blog-subtxt">To answer this question, we will have to take a deep dive into the history of web development. Worldwide Web, which was later renamed Nexus, was the first ever web browser, and it was first released in 1990. Many web browsers followed soon, but they were still not used widely until 1996 when Netscape launched their Netscape Navigator, a web browser that changed how we accessed the internet. The Netscape Navigator laid the basics of modern web browsers, and even today, many features are taken directly from the Netscape Navigator. But what made Netscape Navigator different from the already existing web browsers? </p>
                                <p className="blog-subtxt">For starters, Netscape used virtual machine technology and Java programming language to code their Netscape Navigator browser. They also used JavaScript, a programming language the company created in collaboration with Sun Microsystems, to help coders create more dynamic, responsive, and attractive websites. The introduction of JavaScript was a revolutionary change, and it wasn’t long before it became the most widely used programming language for designing and creating websites. This practice continues to this date.</p>
                                <p className="blog-subtxt">Over the years, developers have tried to code their websites using languages different from JavaScript. Still, none of them could deliver similar results, which is one of the major reasons why even after nearly 25 years, JavaScript is still unbeaten in the web development industry. On the one hand, where JavaScript is the reason web browsers and websites are much more advanced, it’s also why websites and web browsers cannot perform complex applications, and this is exactly where Web Assembly comes into play. </p>
                                <p className="blog-subtxt">In this article, we will discuss everything you need to know about Web Assembly, and why modern web browsers need Web Assembly. So, let’s get started. </p>

                                {/* <img className="blogim2" src="../assets/images/blogim2.png" alt="" />
                                <p className="blog-subtxt blog-subtxt2">Image caption goes here</p> */}

                                {/* <h6 className="blog-head3">Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.</h6>
                                <p className="blog-subtxt">Collaboratively deploy intuitive partnerships whereas customized e-markets. Energistically maintain performance based strategic theme areas whereas just in time methodologies. Phosfluorescently drive functionalized intellectual capital and.</p>
                                <p className="blog-subtxt3">"Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus."</p>
                                <p className="blog-subtxt">Tristique odio senectus nam posuere ornare leo metus, ultricies. Blandit duis ultricies vulputate morbi feugiat cras placerat elit. Aliquam tellus lorem sed ac. Montes, sed mattis pellentesque suscipit accumsan. Cursus viverra aenean magna risus elementum faucibus molestie pellentesque. Arcu ultricies sed mauris vestibulum.</p> */}
                                <h6 className="blog-subhead">JavaScript and Web Development: The Perfect Match?</h6>
                                <p className="blog-subtxt">JavaScript is a programming language that Netscape developed in 1995, and ever since, developers have been using it to meet the ever-growing demands of the internet & web browsers. Although JavaScript can be used to code various applications like games, applications, and even blockchain, the web development industry harnesses this language’s unlimited potential. </p>
                                <p className="blog-subtxt">Before Netscape introduced JavaScript, web developers used the HTML coding language to create websites and web pages. The websites built on HTML were basic and looked like a few lines bunched together. However, things changed when developers started using JavaScript because they could now make the websites more interactive by adding animations, new features, and more. </p>
                                <p className="blog-subtxt">Initially, JavaScript was used only for the front-end development of a website. After the introduction of Node.JS, developers could code the entire website, including the backend using just JavaScript. </p>

                                <h6 className="blog-subhead">How Does JavaScript Work? </h6>
                                <p className="blog-subtxt">As a client-side scripting language, JavaScript runs locally on the host’s browser and does not rely on any external device. Thanks to its client-side scripting, JavaScript does not require web browsers to interact with external servers, unlike web applications, where the application sends a data request to the server, and the client then reads the information sent by the server.</p>
                                <p className="blog-subtxt">It is one of the major reasons JavaScript is the most preferred web development language. It allows users to use their browsers without downloading or installing other packages or applications. A great example of a client-side application is the web pages saved locally on your device. You can load these pages on your browser even if your internet connection is down because the browser does not have to fetch the data from an external server. It just reads the files saved locally on your device. </p>
                                <p className="blog-subtxt">So how does JavaScript work behind the scenes on your internet browser? </p>
                                <ul className="blog-num">
                                    <li className="blognum-cntnt">In the first step, the web developers write the JavaScript code they want to run on the browser. It can be anything from adding an animation to adding a feature or something else.</li>
                                    <li className="blognum-cntnt">The code is then passed to a Parser that abstracts the syntax tree from the code.</li>
                                    <li className="blognum-cntnt">The extracted syntax tree is then passed to a converter that converts the syntax tree into a machine code your computer can understand. </li>
                                    <li className="blognum-cntnt"> The code is then executed on your browser.</li>
                                </ul>
                                <p className="blog-subtxt">All these steps happen in the background while you are using your web browser, which is why you are not interrupted while performing any action on the internet, and this feature is called Asynchronous Interaction. Because of asynchronous interaction, most websites today have the autocomplete functionality where you start typing something, and the application or website automatically recommends some terms or phrases that you might be searching for on the internet. </p>
                                <p className="blog-subtxt">When you start typing, the software on your device automatically detects the letters and forwards them to a remote server that sends the suggestions back to the software. The suggested results appear almost instantly as you start typing without interrupting your typing. </p>

                                <h6 className="blog-subhead">JavaScript: Reasons to Use</h6>
                                <p className="blog-subtxt">There are a lot of web development languages in existence, and JavaScript trumps them all. But why do developers prefer JavaScript over any other language? Here are some of the reasons why JavaScript is the go-to programming language for web developers. </p>
                                <ul className="blog-circle">
                                    <li className="blognum-head">
                                        Speed, Speed, and Speed
                                        <p className="blog-subtxt">A major reason why JavaScript ranks amongst the best web development languages is primarily because it’s fast. Being a client-side scripting language, JavaScript is not reliant on external devices, and it works locally on the client’s browser. Resultantly, the JavaScript code running on the browser does not have to exchange information which results in minimal execution time, and hence, it can generate the output much quickly when compared to other languages.</p>
                                        <p className="blog-subtxt">Additionally, JavaScript is also an integrated language which means that a JavaScript code does not have to be compiled every time you are running it. As a result, it’s not only easier and quicker to code in JavaScript, but it’s also quick because you are not wasting time on compiling the code every time you are trying to execute the program. </p>
                                    </li>
                                    <li className="blognum-head">
                                        Simple and Reliable
                                        <p className="blog-subtxt">Another reason why JavaScript is the pick of web development languages is because it’s simple to get started with JavaScript, and code the programs. Resultantly, developers are able to catch on to new features and practices in JavaScript a lot more quickly which then allows them to work on even the most complex projects without much trouble.</p>
                                        <p className="blog-subtxt">Additionally, JavaScript has been around for 25+ years, and being an open-source coding language, developers are always trying their best to further improve it. As a result, JavaScript is a highly reliable coding language that you can always trust to get your job done. </p>
                                    </li>
                                    <li className="blognum-head">
                                        Wide Compatibility
                                        <p className="blog-subtxt">Developers always have an affinity for a programming language that can work well with other programming languages because it saves them a lot of time & effort, and JavaScript has a wide compatibility with other languages. The interoperability of JavaScript allows developers to deploy their codes on multiple platforms and solve different problems while saving a lot of effort and resources. </p>
                                    </li>
                                </ul>

                                <h6 className="blog-subhead">JavaScript Limitations</h6>
                                <p className="blog-subtxt">Despite being one of the best languages for web development, JavaScript is still imperfect, as it has limitations. Here are some of the disadvantages of using JavaScript for developing web applications.</p>
                                <ul className="blog-circle">
                                    <li className="blognum-head">
                                        Less Emphasis on Client-Side Security
                                        <p className="blog-subtxt">A major disadvantage of using JavaScript for developing web applications is that it places very little emphasis on client-side security, as the source code is present on the website, and anyone can access it. </p>
                                        <p className="blog-subtxt">Easy access to source code allows people to misuse or use it without authentication. Additionally, if someone has access to your computer, adding some code to your browser is easy, which can compromise your security. </p>
                                    </li>
                                    <li className="blognum-head">
                                        Browser Support
                                        <p className="blog-subtxt">Although JavaScript has high interoperability, different browsers have different specifications, and thus each browser runs the JavaScript code a bit differently than the others. Resultantly, developers need to run the JavaScript code on different browsers before releasing the code to ensure that the code runs equally well across all browsers. </p>
                                    </li>
                                    <li className="blognum-head">
                                        Difficult to Debug
                                        <p className="blog-subtxt">Another major limitation of JavaScript is it’s difficult to debug, and debugging in JavaScript is not up to the level of Python or C++. Even if there is some logical or syntax error in the code, the browser does not show any error, which makes it difficult to pinpoint the error in the code. </p>
                                    </li>
                                    <li className="blognum-head">
                                        Bitwise Functions
                                        <p className="blog-subtxt">Although it’s not a huge disadvantage, it’s still a limitation that in JavaScript, a number is stored as a 64-bit floating number, whereas operators work with 32-bit operands. As a result, whenever JavaScript has to work on mathematical operations, it first needs to convert the numbers from 64-bit to 32-bit, perform the mathematical operations, and then convert the result back to 64-bit numbers.</p>
                                        <p className="blog-subtxt">The conversion process from 64-bit to 32-bit and then to 64-bit again takes some time, and it increases the time it takes to execute the commands.</p>
                                    </li>
                                </ul>

                                <h6 className="blog-subhead">Introduction to Web Assembly</h6>
                                <p className="blog-subtxt">Web Assembly, or Wasm, is a binary code language that’s quite similar to assembly-level language, and it’s a new concept that aims to revolutionize the capabilities of web browsers forever. Web Assembly aims to significantly boost web browsers’ performance so that web browsers can run complex web applications seamlessly. </p>
                                <p className="blog-subtxt">But what makes Web Assembly different from other web development languages that were introduced to replace JavaScript and could not do so? What makes Web Assembly different? How does Web Assembly work? And why is the future of web development in dire need of Web Assembly? Let’s answer all these questions in the next sections. </p>

                                <h6 className="blog-subhead">What is Web Assembly?</h6>
                                <p className="blog-subtxt">Web Assembly, or Wasm, is a binary code format that allows web browsers to execute binary code. The Web Assembly is independent of the language. It’s quite close to binary codes meaning that developers can use other languages to compile Web Assembly. They can then execute it on virtual machines, Web APIs, or web browsers. The official website states, “Web Assembly is a binary instruction format for a stack-based virtual machine. Wasm is designed as a portable compilation target for programming languages, enabling deployment on the web for client and server applications.” </p>
                                <p className="blog-subtxt">Web Assembly aims to improve web browsers’ performance and capabilities while ensuring that ecosystem compatibility and standards are maintained. With Web Assembly, developers can run C++ programs on web browsers without any performance issues, and it’s the reason why Web Assembly has the potential to boost the capabilities of modern-day web browsers. </p>
                                <p className="blog-subtxt">But what makes Web Assembly different from other languages introduced to replace JavaScript? Unlike other languages aimed at dethroning JavaScript as the most favored web development language, Web Assembly does not aim to replace JavaScript; rather, it’s more like a companion to JavaScript. Web Assembly has been developed to fill the gap and limitations of JavaScript and not to replace it. </p>

                                <h6 className="blog-subhead">Web Assembly: Essential Features </h6>
                                <p className="blog-subtxt">Now that we know what Web Assembly is let us have a look at some of Web Assembly’s main features. </p>
                                <ul className="blog-circle">
                                    <li className="blognum-head">
                                        Fast and Efficient
                                        <p className="blog-subtxt">Web Assembly deals with binary codes, and the developers have encoded the Web Assembly stack machine in a binary format that’s size efficient and reduces the loading time significantly. Web Assembly aims to exploit common hardware capabilities available across different platforms to execute the commands at native speeds.</p>
                                    </li>
                                    <li className="blognum-head">
                                        Security
                                        <p className="blog-subtxt">A major void of web development with JavaScript is that it’s not highly secure because the code runs directly on the browser, and anyone can access it. However, web development with Web Assembly boosts the security as it deploys a sandboxed execution environment that’s memory-safe, and developers can implement it even within already existing web browsers and JavaScript virtual environments. Additionally, Web Assembly also enforces the same security requirements implemented by the browser.</p>
                                    </li>
                                    <li className="blognum-head">
                                        Open and Debug gable
                                        <p className="blog-subtxt">Unlike JavaScript, developers have designed Web Assembly in such a way that it has a helpful textual format when testing, debugging, optimizing, experimenting, teaching, learning, or writing programs. Users can use the textual format when viewing Wasm modules’ sources online. </p>
                                    </li>
                                    <li className="blognum-head">
                                        Open Web Platform
                                        <p className="blog-subtxt">One of the reasons why Web Assemble is said to be the future of web development is because it’s part of the Open Web Platform and can be maintained as a feature-tested, version-less, and backward-compatible part of the web. Web Assemble modules can move in and out of JavaScript contexts, using Web APIs to access browser functionality. </p>
                                    </li>
                                </ul>

                                <h6 className="blog-subhead">How Web Assembly Works? </h6>
                                <p className="blog-subtxt">Now that we know what Web Assembly is, it’s time for us to understand how Web Assembly works. Before diving deep into the working of Web Assembly, it’s important to note that Web Assembly is not a programming language. Rather, it’s a “compilation target.” But what does it mean? </p>
                                <p className="blog-subtxt">It means that developers do not use Web Assembly to write code; they write it in a programming language of their choice and then compile their code into Web Assembly using bytecode. The bytecode is then passed to the web browser, where the browser converts it into machine code and then executes it at high speed and with a lot of efficiencies.</p>
                                <p className="blog-subtxt">Resultantly, the Web Assembly code runs, parses, compiles, and executes faster when compared to its JavaScript counterpart. However, unlike JavaScript, Web Assembly still requires users to download the Wasm module and set it up on their device locally. As a result, there is some delay in the overall execution, but the end results are still super-fast & efficient.</p>
                                <p className="blog-subtxt">At the end of the day, Web Assembly has been designed to run code written in different languages on the web at super-fast speeds alongside JavaScript by making use of Wasm JavaScript APIs. Developers can load Web Assembly modules into a JavaScript editor and take advantage of the functionalities of both. As a result, developers can benefit from Web Assembly’s power & performance without missing out on the flexibility & expressiveness offered by JavaScript.</p>

                                <h6 className="blog-subhead">Web Assembly and Web Development</h6>
                                <p className="blog-subtxt">So, is Web Assembly powerful enough to replace JavaScript and emerge as the future of web development? There is no definite answer to whether Web Assembly can replace JavaScript because Web Assembly was never introduced to replace JavaScript. Despite its capabilities, JavaScript is not a perfect language, and Web Assembly was introduced to fill the void left by JavaScript, especially when dealing with complex problems. </p>
                                <p className="blog-subtxt">JavaScript is the most preferred language for web development because it provides developers with a lot of flexibility when working with web applications. Additionally, JavaScript has been around for over two decades, as a result of which it has a huge online community, libraries, and frameworks that are of great help to developers. </p>
                                <p className="blog-subtxt">On the other hand, we have Web Assembly, a compact binary format that aims at providing developers to work with C++ and other languages code directly on the web browser to achieve performance at par with native-level applications. When working in sync with each other, JavaScript and Web Assembly have the power to revolutionize web applications forever. </p>

                                <h6 className="blog-subhead">Web Assembly: Use Cases </h6>
                                <p className="blog-subtxt">With the power to aid web development, Web Assembly has a lot of opportunities in the web development field. However, Web Development is not the only use case of Web Assembly, as it can be used in the following industries as well.</p>
                                <ul className="blog-circle">
                                    <li className="blognum-head">
                                        Game Development.
                                    </li>
                                    <li className="blognum-head">
                                        Video editing and rendering.
                                    </li>
                                    <li className="blognum-head">
                                        AR and VR applications.
                                    </li>
                                    <li className="blognum-head">
                                        Image Operations and Computer Vision.
                                    </li>
                                </ul>

                                <h6 className="blog-subhead">Final Thoughts</h6>
                                <p className="blog-subtxt">Thank you for staying with us till the end and taking the initiative to understand what Web Assembly is. Web Assembly is one of the hottest topics of discussion in the web development industry, and for good reasons, as it might be the technology that can change the way we are used to seeing & using the internet. In this article, we have discussed what web assembly is and how it has huge potential in the web development industry.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogTwo;