// import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Header from './Components/Common/Header/Header';
import Home from './Components/Pages/Home/Home';
import Footer from './Components/Common/Footer/Footer';
import AboutUs from './Components/Pages/AboutUs/AboutUs';
import Metaverse from './Components/Pages/Services/Metaverse';
import Nft from './Components/Pages/Services/Nft';
import BusinessOutsourcing from './Components/Pages/Services/BusinessOutsourcing';
import DevOpsSolutions from './Components/Pages/Services/DevOpsSolutions';
import MobileApplication from './Components/Pages/Services/MobileApplication';
import InformationTechnology from './Components/Pages/Services/InformationTechnology';
import BlockchainTechnology from './Components/Pages/Services/BlockchainTechnology';
import Startaproject from './Components/Pages/Startaproject';
import BlogOne from './Components/Pages/Blogs/BlogOne';
import BlogTwo from './Components/Pages/Blogs/BlogTwo';
import BlogThree from './Components/Pages/Blogs/BlogThree';
import ACaseStudyonSmashitLive from './Components/Pages/CaseStudies/ACaseStudyonSmashitLive/ACaseStudyonSmashitLive';
import DevOpsCaseStudy from './Components/Pages/CaseStudies/DevOpsCaseStudy/DevOpsCaseStudy';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/home' element={<Home />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/metaverse' element={<Metaverse />} />
        <Route path='/nft' element={<Nft />} />
        <Route path='/business-outsourcing' element={<BusinessOutsourcing />} />
        <Route path='/devops-solutions' element={<DevOpsSolutions />} />
        <Route path='/mobile-application' element={<MobileApplication />} />
        <Route path='/information-technology' element={<InformationTechnology />} />
        <Route path='/blockchain-technology' element={<BlockchainTechnology />} />
        <Route path='/start-a-project' element={<Startaproject />} />
        <Route path='/Web3' element={<BlogOne />} />
        <Route path='/WebAssembly' element={<BlogTwo />} />
        <Route path='/BlockchainTechnology' element={<BlogThree />} />
        <Route path='/AboutUs' element={<AboutUs />} />
        <Route path='/app-case-study-smashitlive' element={<ACaseStudyonSmashitLive />} />
        <Route path='/devops-case-study-coinovy' element={<DevOpsCaseStudy />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
