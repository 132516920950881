import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Startaproject = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const initialFormData = {
        firstname: '',
        lastname: '',
        mobile: '',
        email: '',
        service: '',
        comments: '',
        agree: false
    };
    const [formData, setFormData] = useState(initialFormData);
    const [isLoading, setIsLoading] = useState(false); // State variable to track loading state

    const isFormValid = () => {
        const { firstname, lastname, email, mobile, service, agree } = formData;
        return firstname && lastname && email && mobile && service && agree;
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: newValue,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!isFormValid()) {
                return;
            }
            // Set loading state to true before handling form submission
            setIsLoading(true);
            const response = await fetch('https://contactapi.dltlabz.io/api/user/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            console.log(response);
            if (response.ok) {
                // Function to trigger the toast
                toast.success(`Thanks for reaching out. We'll be back in touch soon.!`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000, // Auto close the toast after 3000ms (3 seconds)
                });
                // Simulate an asynchronous form submission with setTimeout
                setTimeout(() => {
                    // Handle form submission logic here (e.g., API request, etc.)

                    // After form submission, reset the form and loading state
                    setFormData(initialFormData); // Reset the form data after submission
                    setIsLoading(false);
                }, 2000);
                // Handle successful form submission (e.g., show a success message)
                // console.log('Form submitted successfully!');
            } else {
                // Handle errors (e.g., show an error message)
                console.error('Form submission failed.');
            }
        } catch (error) {
            // console.error('Error submitting form:', error);
            toast.error('Error submitting form', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000, // Auto close the toast after 3000ms (3 seconds)
            });
        }
    };

    useEffect(() => {
        const hash = location?.hash;
        if (hash) {
            const section = document.querySelector(hash);
            section?.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    }, []);
    return (
        <>
            <div className="Startaproject-mainsection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img className="Startaproject-im" src="../assets/images/contactus.png" alt="dlt labz contact us" />
                        </div>
                        <div className="col-md-6">
                            <div className="Startaproject-rightsec">
                                <h1 className="Startaproject-head">{t('Startaproject.Lets talk')}</h1>
                                <h1 className="Startaproject-head">{t('Startaproject.about your project!')}</h1>
                                <div className="Startaproject-frm">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="firstname" className="form-label inphead">{t('Startaproject.First name')}</label>
                                                <input
                                                    name="firstname"
                                                    value={formData.firstname}
                                                    onChange={handleChange}
                                                    type="text"
                                                    className="form-control"
                                                    aria-label="Firstname"
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="lastname" className="form-label inphead">{t('Startaproject.Last name')}</label>
                                                <input
                                                    name="lastname"
                                                    value={formData.lastname}
                                                    onChange={handleChange}
                                                    type="text"
                                                    className="form-control"
                                                    aria-label="Lastname"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="email" className="form-label inphead">{t('Startaproject.Email')}</label>
                                                <input
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    type="text"
                                                    className="form-control"
                                                    aria-label="email"
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="mobile" className="form-label inphead">{t('Startaproject.Phone Number')}</label>
                                                <input
                                                    name="mobile"
                                                    value={formData.mobile}
                                                    onChange={handleChange}
                                                    type="text"
                                                    className="form-control"
                                                    aria-label="mobile"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label htmlFor="service" className="form-label inphead">{t('Startaproject.Im looking for a')}</label>
                                                <input
                                                    name="service"
                                                    value={formData.service}
                                                    onChange={handleChange}
                                                    type="text"
                                                    className="form-control"
                                                    aria-label="Service"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor="comments" className="form-label inphead inphead2">{t('Startaproject.Message')}</label>
                                                <textarea
                                                    name="comments"
                                                    value={formData.comments}
                                                    onChange={handleChange}
                                                    type="text"
                                                    className="form-control msgin"
                                                    id="inputAddress2" rows="6"
                                                    placeholder="Type your message..."></textarea>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-7">
                                                <div className="form-check terms-se">
                                                    <input className="form-check-input terms-che"
                                                        name="agree"
                                                        value={formData.agree}
                                                        onChange={handleChange}
                                                        type="checkbox"
                                                    />
                                                    <label htmlFor="agree" className="form-check-label terms-txt">
                                                        {t('Startaproject.I accept the Terms')}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" disabled={isLoading || !isFormValid()} className={isFormValid() ? 'enabled-button' : 'disabled-button'}>{isLoading ? t('Startaproject.Submitting...') : t('Startaproject.Submit')}</button>
                                            <ToastContainer />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Startaproject;