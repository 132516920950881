import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useTranslation } from "react-i18next";

const MobileApplication = () => {
    const { t } = useTranslation();
    const [swiperRef, setSwiperRef] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const hash = location?.hash;
        if (hash) {
            const section = document.querySelector(hash);
            section?.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    });
    return (
        <>
            <div className="MobileApplication-mainsection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="MobileApplication-main">
                                <h1 className="MobileApplication-head">{t('MobileApplication.Welcome to the')}</h1>
                                <h1 className="MobileApplication-head MobileApplication-head2">{t('MobileApplication.Mobile Application')}</h1>
                                <p className="MobileApplication-txt">{t('MobileApplication.Mobile devices and apps are consistently')}
                                </p>
                                <NavLink to={'/Startaproject'}>
                                    <div className="MobileApplication-btbg">
                                        <button className="MobileApplication-bt">{t('MobileApplication.Start a Project')}</button>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img className="MobileApplication-im" src="../assets/images/mobileapplication/Layer_1.png" alt="dlt labz services mobile application" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="MobileApplication-section2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="MobileApplication-section2head">{t('MobileApplication.Mobile App Development Services')}</h2>
                        </div>
                        <div className="col-md-6">
                            <p className="MobileApplication-section2txt">
                                {t('MobileApplication.Our mobile apps provide links')}
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="development-servicesbox">
                                <h6 className="servicesbox-head">{t('MobileApplication.Mobile First')}</h6>
                                <p className="servicesbox-txt">{t('MobileApplication.Our team of mobile app developers includes')}</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="development-servicesbox development-servicesbox2">
                                <h6 className="servicesbox-head">{t('MobileApplication.Web to Mobile')}</h6>
                                <p className="servicesbox-txt">{t('MobileApplication.Mobile web development—optimizing web')}</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="development-servicesbox development-servicesbox3">
                                <h6 className="servicesbox-head">{t('MobileApplication.Enterprise Mobility')}</h6>
                                <p className="servicesbox-txt">{t('MobileApplication.At DLT LABZ, our experts not only procure')}</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="development-servicesbox development-servicesbox4">
                                <h6 className="servicesbox-head">{t('MobileApplication.User Knowledge Design')}</h6>
                                <p className="servicesbox-txt">{t('MobileApplication.DLT LABZ has the specialist')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="development-servicesbox development-servicesbox5">
                                <h6 className="servicesbox-head">{t('MobileApplication.Cross-Platform Analysis')}</h6>
                                <p className="servicesbox-txt">{t('MobileApplication.We provide mobile platforms and assist')}</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="development-servicesbox development-servicesbox6">
                                <h6 className="servicesbox-head">{t('MobileApplication.Scalability and Interoperability')}</h6>
                                <p className="servicesbox-txt">{t('MobileApplication.DLT LABZ application designs integrate')}</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="development-servicesbox development-servicesbox7">
                                <h6 className="servicesbox-head">{t('MobileApplication.Security and Compliance')}</h6>
                                <p className="servicesbox-txt">{t('MobileApplication.As each project requires its own set of')}</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="development-servicesbox development-servicesbox8">
                                <h6 className="servicesbox-head">{t('MobileApplication.Cross-Platform Analysis2')}</h6>
                                <p className="servicesbox-txt">{t('MobileApplication.We adapt quickly to customer demands')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="visionandmission-section visionandmission-section2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="vision-section">
                                <h6 className="vision-head">{t('information-technology.Our Vision')}</h6>
                                <img className="vision-imin" src="../assets/images/Vision.svg" alt="error" />
                                <p className="vision-txt">
                                    {t('information-technology.We aspire to be leaders in business')}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="vision-section mission-section">
                                <h6 className="vision-head">{t('information-technology.Our Mission')}</h6>
                                <img className="vision-imin Mission-imin" src="../assets/images/Mission.svg" alt="error" />
                                <p className="vision-txt">
                                    {t('information-technology.Give modern and future-looking')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="projects-section projects-section2">
                {/* <img className="projectssection-bg" src="../assets/images/projects-section-bg.svg" alt="error" /> */}
                <div className="container">
                    <div className="projects-main">
                        <h6 className="projects-txt">{t('information-technology.Some treasures')}</h6>
                        <div className="row">
                            <div className="col-md-4">
                                <NavLink to={"https://smashit.live"} target="_blank" style={{ cursor: 'pointer', textDecoration: 'none', }}>
                                    <h6 className="project-name">Smashit Live</h6>
                                    <img className="projectim" src="../assets/images/smashitlive.png" alt="error" />
                                </NavLink>
                            </div>
                            <div className="col-md-4">
                                <NavLink to={"https://wavpe.com"} target="_blank" style={{ cursor: 'pointer', textDecoration: 'none', }}>
                                    <h6 className="project-name">Wavpe</h6>
                                    <img className="projectim" src="../assets/images/wavpe.png" alt="error" />
                                </NavLink>
                            </div>
                            <div className="col-md-4">
                                <NavLink to={"https://www.coinovy.com"} target="_blank" style={{ cursor: 'pointer', textDecoration: 'none', }}>
                                    <h6 className="project-name">Coinovy</h6>
                                    <img className="projectim" src="../assets/images/coinovy.png" alt="error" />
                                    {/* <div className="row">
                                        <div className="col-12">
                                            <h6 className="project-name">Coinovy</h6>
                                        </div>
                                    </div> */}
                                </NavLink>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <NavLink to={"https://zeroheros.io/index"} target="_blank" style={{ cursor: 'pointer', textDecoration: 'none', }}>
                                    <h6 className="project-name">ZeroHeros</h6>
                                    <img className="projectim" src="../assets/images/zeroheros.png" alt="error" />
                                    {/* <div className="row">
                                        <div className="col-12">
                                            <h6 className="project-name">ZeroHeros</h6>
                                        </div>
                                    </div> */}
                                </NavLink>
                            </div>
                            <div className="col-md-4">
                                <NavLink to={"https://appadvice.com/app/vzero-experience-parking/1560777819.amp"} target="_blank" style={{ cursor: 'pointer', textDecoration: 'none', }}>
                                    <h6 className="project-name">V Zero</h6>
                                    <img className="projectim" src="../assets/images/vzero.png" alt="error" />
                                    {/* <div className="row">
                                        <div className="col-12">
                                            <h6 className="project-name">V Zero</h6>
                                        </div>
                                    </div> */}
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section6-faq">
                <div className="container">
                    <h2 className="section6-head">{t('MobileApplication.FAQ')}</h2>
                    <h2 className="section6-head">{t('MobileApplication.Find out what matters to you!')}</h2>
                    <div>
                        <div className="wrapper">
                            <div className="accordion accordion-flush" id="myAccordion">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne"> <span>01 </span>{t('MobileApplication.What are mobile application services and solutions?')}</button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('MobileApplication.Mobile application services and')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo"> <span>02 </span>{t('MobileApplication.Why do businesses need mobile application services?')}</button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('MobileApplication.Mobile applications have become')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree"> <span>03 </span>{t('MobileApplication.How much does it cost to develop a mobile application?')} </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('MobileApplication.The cost of developing a mobile application')} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour"> <span>04 </span>{t('MobileApplication.Are mobile application services limited to certain industries?')}</button>
                                    </h2>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('MobileApplication.No, mobile application services are not')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive"> <span>05 </span>{t('MobileApplication.How long does it take to develop a mobile application?')} </button>
                                    </h2>
                                    <div id="flush-collapseFive" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('MobileApplication.The development timeline for a mobile')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default MobileApplication;