import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useTranslation } from "react-i18next";

const Nft = () => {
    const { t } = useTranslation();
    const [swiperRef, setSwiperRef] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const hash = location?.hash;
        if (hash) {
            const section = document.querySelector(hash);
            section?.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    });
    return (
        <>
            <div className="mainsection">
                <img className="mainbgcircle" src="../assets/images/mainbgcircle.svg" alt="" />
                <img className="Metaverse1 nftim1" src="../assets/images/nft/RobotNFT.png" alt="" />
                <img className="Metaverse1 nftim4" src="../assets/images/nft/DrugNFT.png" alt="" />
                <img className="Metaverse2 nftim2" src="../assets/images/nft/AmericanFootballPlayerNFT.png" alt="" />
                <img className="Metaverse3 nftim3" src="../assets/images/nft/FootballerNFT.png" alt="" />
                {/*  */}
                <div className="maintxt-section">
                    <h1 className="maintxt1">{t('NFT.Welcome to the')}</h1>
                    <h1 className="maintxt1 maintext2">{t('NFT.NFT World')}</h1>
                    <h1 className="maintxt1">{t('NFT.A New Reality Unveiled')}</h1>
                    <p className="maintxtsub">
                        {t('NFT.We offer a comprehensive design and development')}
                    </p>
                    <ul className="mainse-bts">
                        <li>
                            <NavLink to={'/Startaproject'}>
                                <div className="mainbts-bg">
                                    <button className="mainbts mainbts-metaverse">{t('NFT.Start Project')}</button>
                                </div>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="secondsection-forall">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="secondsectionforall-left">
                                <h2 className="section-mainhead">
                                    {t('NFT.Bring in powerful business prospects with NFT')}
                                </h2>
                                {/* <h2 className="section-mainhead">
                                    prospects with NFT
                                </h2> */}
                                <p className="section-maintxt">
                                    {t('NFT.Digital assets like these are rare')}
                                </p>
                                <p className="section-maintxt">
                                    {t('NFT.DLT Labz is here to help you design')}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <img className="section-mainim" src="../assets/images/nft/Group117.png" alt="dlt labz services nft development" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="Metaverse-section3">
                <div className="container">
                    <h2 className="Metaversesection3-head">NFT </h2>
                    <h2 className="Metaversesection3-head">{t('NFT.Consulting')}</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="metaverse-left">
                                <ul className="mleft-lis">
                                    <li>
                                        <p className="mleft-txt"> <span><img className="mleft-im" src="../assets/images/greenandwhite.svg" alt="" /></span>{t('NFT.Ideation of business-specific NFTs')}</p>
                                    </li>
                                    <li>
                                        <p className="mleft-txt"><span><img className="mleft-im" src="../assets/images/greenandwhite.svg" alt="" /></span>{t('NFT.Analyzing and fine-tuning strategies')}</p>
                                    </li>
                                    <li>
                                        <p className="mleft-txt"><span><img className="mleft-im" src="../assets/images/greenandwhite.svg" alt="" /></span>{t('NFT.Token & protocol selection, tokenomics mapping')}</p>
                                    </li>
                                    <li>
                                        <p className="mleft-txt"><span><img className="mleft-im" src="../assets/images/greenandwhite.svg" alt="" /></span>{t('NFT.Tracking and supporting progress')}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="metaverse-left metaverse-right">
                                <p className="mright-txt">
                                    {t('NFT.With our help, we can help you develop NFTs')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <NavLink to={'/Startaproject'}><button className="Metaversesection3-bt">{t('NFT.Start Project')}</button></NavLink>
                </div>
            </div>
            <div className="visionandmission-section visionandmission-section2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="vision-section">
                                <h6 className="vision-head">{t('information-technology.Our Vision')}</h6>
                                <img className="vision-imin" src="../assets/images/Vision.svg" alt="error" />
                                <p className="vision-txt">
                                    {t('information-technology.We aspire to be leaders in business')}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="vision-section mission-section">
                                <h6 className="vision-head">{t('information-technology.Our Mission')}</h6>
                                <img className="vision-imin Mission-imin" src="../assets/images/Mission.svg" alt="error" />
                                <p className="vision-txt">
                                    {t('information-technology.Give modern and future-looking')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="secondsection-forall secondsection-forall2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="secondsectionforall-left">
                                <h2 className="section-mainhead section-mainhead2">
                                    {t('NFT.NFT Marketplace Development')}
                                </h2>
                                <p className="section-maintxt section-maintxt2">
                                    {t('NFT.We build highly appealing NFT marketplaces')}
                                </p>
                                <ul className="sectionforall-list">
                                    <li className="sectionall-txt">{t('NFT.Open & permissioned NFT marketplaces')}</li>
                                    <li className="sectionall-txt">{t('NFT.FP, auctioning & packaging of commercial models.')}</li>
                                    <li className="sectionall-txt">{t('NFT.Advanced verification systems')}</li>
                                </ul>
                                <p className="section-maintxt section-maintxt2">
                                    {t('NFT.Whatever you need to develop your')}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <img className="section-mainim" src="../assets/images/nft/image111.png" alt="dlt labz services nft marketplace development" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="projects-section projects-section2">
                {/* <img className="projectssection-bg" src="../assets/images/projects-section-bg.svg" alt="" /> */}
                <div className="container">
                    <div className="projects-main">
                        <h6 className="projects-txt">{t('information-technology.Some treasures')}</h6>
                        <div className="row">
                            <div className="col-md-4">
                                <NavLink to={"https://sanjubaba.io/home"} target="_blank" style={{ cursor: 'pointer', textDecoration: 'none', }}>
                                    <h6 className="project-name">Sanjubaba</h6>
                                    <img className="projectim" src="../assets/images/sanjubaba.png" alt="" />
                                </NavLink>
                            </div>
                            <div className="col-md-4">
                                <NavLink to={"https://www.starmetaverse.io"} target="_blank" style={{ cursor: 'pointer', textDecoration: 'none', }}>
                                    <h6 className="project-name">Star Metaverse</h6>
                                    <img className="projectim" src="../assets/images/starmetaverse.png" alt="" />
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section6-faq">
                <div className="container">
                    <h2 className="section6-head">{t('NFT.FAQ')}</h2>
                    <h2 className="section6-head">{t('NFT.Find out what matters to you!')}</h2>
                    <div>
                        <div className="wrapper">
                            <div className="accordion accordion-flush" id="myAccordion">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne"> <span>01 </span>{t('NFT.What is an NFT Marketplace?')}</button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('NFT.An NFT Marketplace is a digital platform')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo"> <span>02 </span>{t('NFT.Why should I consider developing an NFT Marketplace?')}</button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('NFT.Developing an NFT Marketplace can unlock')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree"> <span>03 </span>{t('NFT.What NFT Marketplace development services do you offer?')} </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('NFT.Our NFT Marketplace development services')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour"> <span>04 </span>{t('NFT.How do you ensure security on the NFT Marketplace?')}</button>
                                    </h2>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('NFT.We prioritize security by implementing')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive"> <span>05 </span>{t('NFT.Can you integrate my NFT Marketplace with specific blockchain networks?')}</button>
                                    </h2>
                                    <div id="flush-collapseFive" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('NFT.Yes, we specialize in integrating NFT')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix"> <span>06 </span>{t('NFT.What features can be included in my NFT Marketplace?')} </button>
                                    </h2>
                                    <div id="flush-collapseSix" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('NFT.Your NFT Marketplace can have features such')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven"> <span>07 </span>{t('NFT.What is the process of developing an NFT Marketplace?')}</button>
                                    </h2>
                                    <div id="flush-collapseSeven" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('NFT.Our development process involves')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight"> <span>08 </span>{t('NFT.How can I monetize my NFT Marketplace?')}</button>
                                    </h2>
                                    <div id="flush-collapseEight" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('NFT.You can generate revenue through transaction')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine"> <span>09 </span>{t('NFT.How do I get started with developing my NFT Marketplace?')} </button>
                                    </h2>
                                    <div id="flush-collapseNine" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('NFT.To get started, simply reach out to us via')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Nft;