import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
// import { Helmet } from "react-helmet";

const AboutUs = () => {
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        const hash = location?.hash;
        if (hash) {
            const section = document.querySelector(hash);
            section?.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    });
    return (
        <>
            {/* <Helmet>
                <meta charSet="utf-8" />
                <title>About Us</title>
            </Helmet> */}
            <div className="aboutus-section">
                <img className="aboutbg" src="../assets/images/mainbgcircle.svg" alt="error" />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="aboutus-headersec">
                            <img className="aboutus-headerim2" src="../assets/images/aboutusim.png" alt="" />
                            <h1 className="aboutis-head">{t('AboutUS.At DLT Labz, we believe in making technology work for you.')}</h1>
                            <p className="aboutus-txt">{t('AboutUS.Welcome to DLT Labz, where technology meets')}</p>
                        </div>
                    </div>
                </div>

                <div className="ourstory-section">
                    <img className="aboutbg2" src="../assets/images/blogbgi2.png" alt="error" />
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <p className="ourstory">{t('AboutUS.Our story')}</p>
                                <h2 className="ourstory-head">{t('AboutUS.Were with you every step of the way - from concept to creation.')}</h2>
                            </div>
                            <div className="col-md-6">
                                <div className="ourstory-right">
                                    <p className="ourstoryright-txt">
                                        {t('AboutUS.At DLT Labz, we understand the power of technology')}
                                    </p>
                                    <p className="ourstoryright-txt">
                                        {t('AboutUS.We take pride in our ability to deliver')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ourstory-videosec">
                        <div className="container">
                            <video className="ourstory-vid" poster="../assets/video/video-img.png" controls>
                                <source src="../assets/video/DLTLabz.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ourstory-section4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="storyhead1">{t('AboutUS.Client-Centric Mobile and Web Solutions')}</h2>
                            <p className="storytxt1">
                                {t('AboutUS.At DLT LABZ, our commitment to excellence is reflected')}
                            </p>
                            <div className="row">
                                <div className="col-4">
                                    <h2 className="strytitle1">25+</h2>
                                    <p className="strytxt1">{t('AboutUS.Projects completed')}</p>
                                </div>
                                <div className="col-4">
                                    <h2 className="strytitle1">$5m</h2>
                                    <p className="strytxt1">{t('AboutUS.Funded')}</p>
                                </div>
                                <div className="col-4">
                                    <h2 className="strytitle1">200%</h2>
                                    <p className="strytxt1">{t('AboutUS.Year on year growth')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img className="storyhead-im" src="../assets/images/aboutusimg.png" alt="dlt labz logo" />
                        </div>
                    </div>
                </div>
            </div>


            <div className="section5-main2 section5-main24">
                <div className="container">
                    <h2 className="section5main2-head">The DLT LABZ BLOG</h2>
                    <p className="section5main2-txt">{t('AboutUS.Join us as we delve deep')}</p>
                    <div className="section5main2-slide">
                        <div className="row">
                            <div className="col-md-4">
                                <NavLink style={{ textDecoration: 'none' }} to={'/Web3'}>
                                    <div className="section5main2-slide2">
                                        <img className="section5main2slide-im" src="../assets/images/blog/blog1.png" alt="" />
                                        <h6 className="section5main2slide-txt2">{t('AboutUS.What is Web3? A Friendly Introduction')}</h6>
                                        <p className="section5main2slide-txt3">{t('AboutUS.Web3 has become more than a')} </p>
                                        <p className="read-more">{t('AboutUS.Read More...')}</p>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-md-4">
                                <NavLink style={{ textDecoration: 'none' }} to={'/BlockchainTechnology'}>
                                    <div className="section5main2-slide2">
                                        <img className="section5main2slide-im" src="../assets/images/blog/blog3.png" alt="" />
                                        <h6 className="section5main2slide-txt2">{t('AboutUS.DLT LABZ Expertise the Blockchain Technology')}</h6>
                                        <p className="section5main2slide-txt3">{t('AboutUS.At DLT LABZ, we are a socially')}</p>
                                        <p className="read-more">{t('AboutUS.Read More...')}</p>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-md-4">
                                <NavLink style={{ textDecoration: 'none' }} to={'/WebAssembly'}>
                                    <div className="section5main2-slide2">
                                        <img className="section5main2slide-im" src="../assets/images/blog/blog2.png" alt="" />
                                        <h6 className="section5main2slide-txt2">{t('AboutUS.An Introduction to Web Assembly')}</h6>
                                        <p className="section5main2slide-txt3">{t('AboutUS.Today, web browsers are an indispensable')} ... </p>
                                        <p className="read-more">{t('AboutUS.Read More...')}</p>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutUs;