import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

const BlogOne = () => {
    const location = useLocation();

    useEffect(() => {
        const hash = location?.hash;
        if (hash) {
            const section = document.querySelector(hash);
            section?.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    });

    return (
        <>
            <div className="blog-mainsection">
                <img className="blog-blogbgi" src="../assets/images/blogbgi.png" alt="" />
                <img className="blog-blogbgi2" src="../assets/images/blogbgi2.png" alt="" />
                <div className="container">
                    <div className="blogtitle-section">
                        <h2 className="blog-title">What is Web3? A Friendly Introduction</h2>
                        <NavLink className="blog-back" to={'/'}> <img className="blog-backim" src="../assets/images/left-arrow-blog.svg" alt="" /></NavLink>
                    </div>
                    <div className="blog-bgim">
                        <img className="" src="../assets/images/blog/blog1-2.png" alt="" />
                    </div>
                </div>

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-9">
                            <div className="blogcontent-section">
                                <h6 className="blog-subhead">Introduction</h6>
                                <p className="blog-subtxt">Web3 has become more than a concept but a reality. It is a step further in developing the web. Web3 promises more decentralization of information than was available before now.</p>
                                <p className="blog-subtxt">Web1.0 and Web2.0 ushered in major development and technological advancement. With Web3, we will surely see barriers and limits shattered because of the sophistication and opportunities it provides. </p>
                                <p className="blog-subtxt">This article explores Web3 beyond the fuss by people without delving too deep because each component in web3 should be a discussion on its own. </p>

                                {/* <img className="blogim2" src="../assets/images/blogim2.png" alt="" />
                                <p className="blog-subtxt blog-subtxt2">Image caption goes here</p> */}

                                {/* <h6 className="blog-head3">Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.</h6>
                                <p className="blog-subtxt">Collaboratively deploy intuitive partnerships whereas customized e-markets. Energistically maintain performance based strategic theme areas whereas just in time methodologies. Phosfluorescently drive functionalized intellectual capital and.</p>
                                <p className="blog-subtxt3">"Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus."</p>
                                <p className="blog-subtxt">Tristique odio senectus nam posuere ornare leo metus, ultricies. Blandit duis ultricies vulputate morbi feugiat cras placerat elit. Aliquam tellus lorem sed ac. Montes, sed mattis pellentesque suscipit accumsan. Cursus viverra aenean magna risus elementum faucibus molestie pellentesque. Arcu ultricies sed mauris vestibulum.</p> */}
                                <h6 className="blog-subhead">Web1.0 & Web2.0 </h6>
                                <p className="blog-subtxt">Web1.0 refers to the earliest form of the World Wide Web (WWW). It is commonly called the read-only web because it only conveys information to users. Web1.0 can be likened to a single-track road where, in this case, users can only read data from the internet; they cannot write to it.</p>
                                <p className="blog-subtxt">In the early days of Web 1.0, most of the internet’s content consisted of static web pages that users would visit, read, and interact with. Web2.0 exists to correct the inefficiencies of web1.0. </p>
                                <p className="blog-subtxt">With Web3 came better interfaces, experiences, and interactivity; however, knowledge, power, and control was centralized. Big tech companies have the sole right to control what information is available to the people and when it is available.</p>
                                <p className="blog-subtxt">Eventually, this led to the issue of trust, permission, and decentralization. The concerns above brought about the decentralized, trustless, and permissionless web, Web3.</p>

                                <h6 className="blog-subhead">Web3: The Future of the Internet</h6>
                                <p className="blog-subtxt">Web3 is used to ascribe a different level of intelligence and decentralization to the internet in addition to the read and write-only quality it already possesses. It is somewhat difficult to give a definite explanation as to what web3 is; instead, it is explained by the qualities it possesses, and these qualities include:</p>
                                <ul className="blog-num">
                                    <li className="blognum-cntnt">Decentralization and Ownership of Information: Web3 combines different emerging internet applications on blockchain technology. The aim is to manifest a digital experience that is more resilient, more inclusive, and prioritizes peer-to-peer connection. </li>
                                    <li className="blognum-cntnt">Permissionless & Trustless: Besides being decentralized and open source for everyone to participate in, web3 is also trustless. The permission of a selected group can be sought after any decision is made. Everyone contributes to its development. </li>
                                    <li className="blognum-cntnt">Connectivity and ubiquity: Information and content are increasingly accessible and omnipresent with Web 3.0, which can be accessed by many applications and a growing number of daily objects connected to the internet, such as the Internet of Things.</li>
                                </ul>

                                <h6 className="blog-subhead">Decentralized Autonomous Organizations (DAOs)</h6>
                                <p className="blog-subtxt">Decentralized Autonomous Organizations (DAOs) are crucial to the achievement of complete decentralization in web3. It is a system that enables everyone to participate in the decentralization of the internet.</p>
                                <p className="blog-subtxt">DAOs operate through smart contracts. Smart contracts contain the logic for what quorum would be for any decision-making process and what each vote carries. A good example of this is snapshot.</p>
                                <p className="blog-subtxt">The announced benefit of DAOs is public participation. Everyone is welcome to participate in the decision-making process. The criteria for participation for many protocols is ownership of their native tokens.</p>
                                <p className="blog-subtxt">With these tokens/cryptocurrencies, users can signify their participation. A user is welcome to submit a proposal, and the DAO or community members can vote on it.</p>

                                <h6 className="blog-subhead">Decentralized Finance (DeFi): The Protagonist of Web3 </h6>
                                <p className="blog-subtxt">Web3 is an ecosystem with its financial system. The financial system in web3 is called Decentralized Finance (DeFi). Investopedia defined Decentralized Finance as an emerging financial technology based on secure distributed ledgers similar to those used by cryptocurrencies. DeFi eliminates the fees banks and other financial companies charge for using their services. Individuals hold money in a secure digital wallet, can transfer funds in minutes, and anyone with an internet connection can use DeFi.</p>
                                <p className="blog-subtxt">Today, the systems, concepts, and structures used in Traditional Finance (TradFi) are being onboarded on the blockchain. The point of this is to break the barrier associated with participation, barriers that exist to prevent people who need the financial services from enjoying it. Most of the products available in TradFi are now available on DeFi. </p>
                                <p className="blog-subtxt">According to DeFiLlama, DeFi has a total Value Locked (TVL) of over $ 59 billion. To put this in perspective, the world’s largest economy, the United States, has a real GDP of $ 20.94 trillion (Source). This is a huge feat even when the technology behind DeFi is still embryonic. </p>
                                <p className="blog-subtxt">DeFi has been the protagonist of Web3 thus far. Different products built on blockchain technology have broken various barriers associated with TradFi. This caused more people to subscribe to the idea of a new internet. Individuals and companies now prefer to borrow funds for their projects from protocols without having to provide as much or even any collateral for borrowing.</p>
                                <p className="blog-subtxt">The growth of Web3 cannot be completely ascribed to decentralized finance, but the growth of web3 is synonymous with the growth of decentralized finance and the protocols therein.</p>

                                <h6 className="blog-subhead">Currency & Tokens </h6>
                                <p className="blog-subtxt">You must discuss cryptocurrencies and tokens to discuss web3 and decentralized finance. There is a need to differentiate between tokens and cryptocurrencies in web3. </p>
                                <p className="blog-subtxt">Tokens are a measure of value like fiat currencies (e.g., USD, EUR, SGD, etc.), but that is not what it is. Tokens can either be fungible or non-fungible. Due to this distinction, the utility of tokens stretches beyond being used as a currency. </p>
                                <p className="blog-subtxt">Tokens can also be used to signify ownership. It can also be used for accounting in the case of vault contracts, and when we consider non-fungible tokens (NFTs), tokens could also signify the preservation of rarity.</p>
                                <p className="blog-subtxt">Tokens are really important in web3. It is used to provide liquidity and to make payments, amongst other things. Many protocols have continually tried to explore even more creative ways to use tokens. More use cases and utilities will surface soon. </p>

                                <h6 className="blog-subhead">Smart Contracts </h6>
                                <p className="blog-subtxt">Smart contract is an aid for web3. It is a set of instructions executed when a predetermined condition is met. Cointelegraph defined smart contracts as </p>
                                <p className="blog-subtxt">“Automated agreements between the contract creator and the recipient. Written in code is an agreement baked into the blockchain, making it immutable and irreversible. They’re usually used to automate the execution of an agreement so that all parties can be sure of the conclusion immediately without any intermediaries. They can also automate a workflow when certain circumstances are satisfied.”</p>
                                <p className="blog-subtxt">Smart contract is essential to web3 because of its automation and flexibility. With smart contracts, protocols like uniswap were able to build a system that enables individuals to provide liquidity in exchange for rewards/ROI, which often beats the ones provided by commercial banks or for keeping the funds at home. </p>
                                <p className="blog-subtxt">This kind of invention is not only revolutionary but also significant in the development of the ecosystem because, as we will have it, it ushered in the creation of even more protocols that boosted the space. </p>
                                <p className="blog-subtxt">Even difficult-to-understand financial concepts, like derivatives and financial swaps, have been simplified by clicking a few buttons on screens and interacting with smart contracts. </p>
                                <p className="blog-subtxt">Smart contracts have ushered in different opportunities. We now have developers building very interesting projects using smart contracts. Smart contracts take away the tedious process of doing things traditionally and the bureaucratic process plagued with accessing financial products and services, among other benefits. </p>

                                <h6 className="blog-subhead">The Metaverse </h6>
                                <p className="blog-subtxt">The metaverse is still in its infancy. It is an artificial space where people can create a virtual persona and exist. The metaverse is a world built with virtual reality and on blockchain technology.</p>
                                <p className="blog-subtxt">In 2022, there were reports of real estate being sold on the metaverse, and business was booming during the bull market. The metaverse, as many people believe, is the future. It is a space that allows you to be a whole new person. The metaverse has yet to gain structure completely because, over the years, its meaning has been constantly restructured. </p>
                                <p className="blog-subtxt">The metaverse is part of the process of full participation in the blockchain. Many of our questions are often answered as we see the metaverse take different shapes and forms. </p>

                                <h6 className="blog-subhead">Conclusion</h6>
                                <p className="blog-subtxt">From decentralized finance, smart contracts, Dapps to tokens, what is important to note is that web3 is still in its infancy. Opportunities are opening up for everyone to participate in web3. </p>
                                <p className="blog-subtxt">Individuals, groups, and companies put on their thinking cap daily, coming up with innovations in web3 and how they can digitize the traditional ways using blockchain technology. The opportunities in the space are enticing. Pick your corner and be the best at it. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogOne;