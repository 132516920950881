import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useTranslation } from "react-i18next";

const BusinessOutsourcing = () => {
    const { t } = useTranslation();
    const [swiperRef, setSwiperRef] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const hash = location?.hash;
        if (hash) {
            const section = document.querySelector(hash);
            section?.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    });
    return (
        <>
            <div className="mainsection">
                <img className="mainbgcircle" src="../assets/images/mainbgcircle.svg" alt="error" />
                {/*  */}
                <div className="maintxt-section">
                    <h1 className="maintxt1">{t('BusinessOutsourcing.Welcome to the')}</h1>
                    <h1 className="maintxt1 maintext2">{t('BusinessOutsourcing.Business Outsourcing')}</h1>
                    <h1 className="maintxt1 maintext2">{t('BusinessOutsourcing.Services')}</h1>
                    <p className="maintxtsub">
                        {t('BusinessOutsourcing.Business Outsourcing Services is a suite')}
                    </p>
                    <ul className="mainse-bts">
                        <li>
                            <NavLink to={'/Startaproject'}>
                                <div className="mainbts-bg">
                                    <button className="mainbts mainbts-metaverse" to={'/start-a-project'}>{t('BusinessOutsourcing.Start a Project')}</button>
                                </div>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="secondsection-forall secondsection-business">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="secondsectionforall-left">
                                <h2 className="section-mainhead">
                                    {t('BusinessOutsourcing.We create software that helps build businesses on technology by working together.')}
                                </h2>
                                <p className="section-maintxt">
                                    {t('BusinessOutsourcing.Business outsourcing isnt just a')}
                                </p>
                                <p className="section-maintxt">
                                    {t('BusinessOutsourcing.DLT LABZ helps you make the most')}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <img className="section-mainim" src="../assets/images/Group5787.png" alt="dlt labz servicesbusiness outsourcing" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="visionandmission-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="vision-section">
                                <h6 className="vision-head">{t('information-technology.Our Vision')}</h6>
                                <img className="vision-imin" src="../assets/images/Vision.svg" alt="error" />
                                <p className="vision-txt">
                                    {t('information-technology.We aspire to be leaders in business')}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="vision-section mission-section">
                                <h6 className="vision-head">{t('information-technology.Our Mission')}</h6>
                                <img className="vision-imin Mission-imin" src="../assets/images/Mission.svg" alt="error" />
                                <p className="vision-txt">
                                    {t('information-technology.Give modern and future-looking')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="projects-section projects-section2">
                {/* <img className="projectssection-bg" src="../assets/images/projects-section-bg.svg" alt="error" /> */}
                <div className="container">
                    <div className="projects-main">
                        <h6 className="projects-txt">{t('information-technology.Some treasures')}</h6>
                        <div className="row">
                            <div className="col-md-4">
                                <NavLink to={"https://www.bmrinnovations.com/home"} target="_blank" style={{ cursor: 'pointer', textDecoration: 'none', }}>
                                    <h6 className="project-name">BMR Innovations</h6>
                                    <img className="projectim" src="../assets/images/bmr.png" alt="error" />
                                </NavLink>
                            </div>
                            <div className="col-md-4">
                                <NavLink to={"https://devotiontourism.com"} target="_blank" style={{ cursor: 'pointer', textDecoration: 'none', }}>
                                    <h6 className="project-name">Devotion Tourism</h6>
                                    <img className="projectim" src="../assets/images/devotion.png" alt="error" />
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section6-faq">
                <div className="container">
                    <h2 className="section6-head">{t('BusinessOutsourcing.FAQ')}</h2>
                    <h2 className="section6-head">{t('BusinessOutsourcing.Find out what matters to you!')}</h2>
                    <div>
                        <div className="wrapper">
                            <div className="accordion accordion-flush" id="myAccordion">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne"> <span>01 </span>{t('BusinessOutsourcing.What is Business Outsourcing?')}</button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('BusinessOutsourcing.Business outsourcing involves contracting')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo"> <span>02 </span>{t('BusinessOutsourcing.Why do companies consider outsourcing?')}</button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('BusinessOutsourcing.Outsourcing offers several advantages')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree"> <span>03 </span>{t('BusinessOutsourcing.What types of services can be outsourced?')}</button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('BusinessOutsourcing.A wide range of services can be outsourced')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour"> <span>04 </span>{t('BusinessOutsourcing.Is data security ensured when outsourcing services?')}</button>
                                    </h2>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('BusinessOutsourcing.Yes, reputable outsourcing providers')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive"> <span>05 </span>{t('BusinessOutsourcing.Can I maintain control over outsourced processes?')}</button>
                                    </h2>
                                    <div id="flush-collapseFive" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('BusinessOutsourcing.Absolutely. While outsourcing involves')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix"> <span>06 </span>{t('BusinessOutsourcing.How can I get started with your Business Outsourcing services?')} </button>
                                    </h2>
                                    <div id="flush-collapseSix" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('BusinessOutsourcing.To begin, reach out to us via our contact page')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default BusinessOutsourcing;