import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useTranslation } from "react-i18next";


const BlockchainTechnology = () => {
    const { t } = useTranslation();
    const [swiperRef, setSwiperRef] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const hash = location?.hash;
        if (hash) {
            const section = document.querySelector(hash);
            section?.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    });
    return (
        <>
            <div className="mainsection">
                <img className="mainbgcircle" src="../assets/images/mainbgcircle.svg" alt="error" />
                {/*  */}
                <div className="maintxt-section">
                    <h1 className="maintxt1">{t('BlockchainTechnology.Welcome to the')}</h1>
                    <h1 className="maintxt1 maintext2">{t('BlockchainTechnology.Blockchain Technology')}</h1>
                    <p className="maintxtsub">
                        {t('BlockchainTechnology.We can assist you with testing and deploying')}
                    </p>
                    <ul className="mainse-bts">
                        <li>
                            <NavLink to={'/Startaproject'}>
                                <div className="mainbts-bg">
                                    <button className="mainbts mainbts-metaverse">{t('BlockchainTechnology.Start a Project')}</button>
                                </div>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="secondsection-forall secondsection-informtexh">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="secondsectionforall-left secondsectionforall-devops">
                                <h2 className="section-mainhead">
                                    {t('BlockchainTechnology.Enterprise blockchain solutions')}
                                </h2>
                                <p className="section-maintxt section-maintxtit">
                                    {t('BlockchainTechnology.Our experts at DLT LABZ know how')}
                                </p>
                                <p className="section-maintxt section-maintxtit">
                                    {t('BlockchainTechnology.Blockchain has transformed the way we')}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <img className="section-mainim section-mainimbt" src="../assets/images/BTGroup11712.png" alt="dlt labz services blockchain technology" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="visionandmission-section visionandmission-section2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="vision-section">
                                <h6 className="vision-head">{t('information-technology.Our Vision')}</h6>
                                <img className="vision-imin" src="../assets/images/Vision.svg" alt="error" />
                                <p className="vision-txt">
                                    {t('information-technology.We aspire to be leaders in business')}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="vision-section mission-section">
                                <h6 className="vision-head">{t('information-technology.Our Mission')}</h6>
                                <img className="vision-imin Mission-imin" src="../assets/images/Mission.svg" alt="error" />
                                <p className="vision-txt">
                                    {t('information-technology.Give modern and future-looking')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="projects-section projects-section2">
                {/* <img className="projectssection-bg" src="../assets/images/projects-section-bg.svg" alt="error" /> */}
                <div className="container">
                    <div className="projects-main">
                        <h6 className="projects-txt">{t('information-technology.Some treasures')}</h6>
                        <div className="row">
                            <div className="col-md-4">
                                <NavLink to={"https://www.coinovy.com"} target="_blank" style={{ cursor: 'pointer', textDecoration: 'none', }}>
                                    <h6 className="project-name">Coinovy</h6>
                                    <img className="projectim" src="../assets/images/coinovy.png" alt="error" />
                                </NavLink>
                            </div>
                            <div className="col-md-4">
                                <NavLink to={"https://zeroheros.io/index"} target="_blank" style={{ cursor: 'pointer', textDecoration: 'none', }}>
                                    <h6 className="project-name">Zero Heros</h6>
                                    <img className="projectim" src="../assets/images/zeroheros.png" alt="error" />
                                </NavLink>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>

            <div className="section6-faq">
                <div className="container">
                    <h2 className="section6-head">{t('BlockchainTechnology.FAQ')}</h2>
                    <h2 className="section6-head">{t('BlockchainTechnology.Find out what matters to you!')}</h2>
                    <div>
                        <div className="wrapper">
                            <div className="accordion accordion-flush" id="myAccordion">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne"> <span>01 </span>{t('BlockchainTechnology.What is Blockchain Technology?')}</button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('BlockchainTechnology.Blockchain is a decentralized and')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo"> <span>02 </span>{t('BlockchainTechnology.How can my business benefit from Blockchain Technology?')} </button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('BlockchainTechnology.Blockchain technology offers benefits')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree"> <span>03 </span>{t('BlockchainTechnology.What Blockchain services do you provide?')}</button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('BlockchainTechnology.Our Blockchain services encompass')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour"> <span>04 </span>{t('BlockchainTechnology.Can you explain the concept of Smart Contracts?')}</button>
                                    </h2>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('BlockchainTechnology.Smart contracts are self-executing')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive"> <span>05 </span>{t('BlockchainTechnology.Can you develop Decentralized Applications (dApps)?')}</button>
                                    </h2>
                                    <div id="flush-collapseFive" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('BlockchainTechnology.Yes, we specialize in developing')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix"> <span>06 </span>{t('BlockchainTechnology.Is Blockchain secure and private?')}</button>
                                    </h2>
                                    <div id="flush-collapseSix" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('BlockchainTechnology.Yes, blockchain offers strong security')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven"> <span>07 </span>{t('BlockchainTechnology.How do I get started with')} </button>
                                    </h2>
                                    <div id="flush-collapseSeven" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('BlockchainTechnology.To get started, reach out to us through')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default BlockchainTechnology;