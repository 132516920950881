import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Header = () => {

    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');

    // Function to handle language change
    const handleLanguageChange = () => {
        // Toggle the language between 'en' and 'ta'
        const newLanguage = language === 'en' ? 'ta' : 'en';
        setLanguage(newLanguage);
        i18n.changeLanguage(newLanguage); // Change the language using i18n
        localStorage.setItem('language', newLanguage);
    };

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "./assets/js/script.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (
        <>
            <header className="container">
                <nav className="navbar navbar-expand-lg">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <NavLink className="navbar-brand" to={'/'}><img src="/assets/images/logo.png" className="headlogo" alt="dlt labz logo" /></NavLink>
                    </div>
                    <div className="bg-lan-clr-mobile">
                        <div className="form-check form-switch" >
                            <input
                                className="form-check-input lang-switch"
                                type="checkbox"
                                id="flexSwitchCheckDefault"
                                onChange={handleLanguageChange}
                                checked={language === 'ta'}
                            />
                        </div>
                    </div>
                    <button className="navbar-toggler mbl-bt" type="button" data-bs-toggle="collapse" data-bs-target="#dlt" aria-controls="dlt" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fas fa-bars" />
                    </button>

                    <div className="collapse navbar-collapse cstmtest" id="dlt">
                        <ul className="navbar-nav mx-auto custom-header">
                            <li className="nav-item">
                                <NavLink className="nav-link active" to={'/'}>{t('header.home')}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to={'/about-us'}>{t('header.About Us')}</NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <div className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {t('header.Services')}
                                </div>
                                <ul className="dropdown-menu drop-bg" aria-labelledby="navbarDropdown">
                                    <li><NavLink className="dropdown-item" to={'/information-technology'}>{t('header.Information Technology')}</NavLink></li>
                                    <li><NavLink className="dropdown-item" to={'/blockchain-technology'}>{t('header.Blockchain Technology')}</NavLink></li>
                                    <li><NavLink className="dropdown-item" to={'/mobile-application'}>{t('header.Mobile Application')}</NavLink></li>
                                    <li><NavLink className="dropdown-item" to={'/devops-solutions'}>{t('header.DevOps Solutions')}</NavLink></li>
                                    <li><NavLink className="dropdown-item" to={'/nft'}>{t('header.NFT')}</NavLink></li>
                                    <li><NavLink className="dropdown-item" to={'/metaverse'}>{t('header.Metaverse')}</NavLink></li>
                                    <li><NavLink className="dropdown-item" to={'/business-outsourcing'}>{t('header.Business Outsourcing')}</NavLink></li>
                                </ul>
                            </li>

                            <li className="nav-item">
                                <NavLink className="nav-link" to={'/start-a-project'}>{t('header.Contact Us')}</NavLink>
                            </li>

                            {/* <li className="nav-item">
                                <div className="form-check form-switch" >
                                    <input
                                        className="form-check-input lang-switch"
                                        type="checkbox"
                                        id="flexSwitchCheckDefault"
                                        onChange={handleLanguageChange}
                                        checked={language === 'ta'}
                                    />
                                </div>
                            </li> */}

                            {/* <button onClick={() => changeLanguage('en')}>en</button>
                            <button onClick={() => changeLanguage('ta')}>ta</button> */}
                        </ul>
                        <div className="form-inline my-2 my-lg-0 d-flex" style={{ alignItems: 'center' }}>
                            <div className="bg-lan-clr">
                                <div className="form-check form-switch" >
                                    <input
                                        className="form-check-input lang-switch"
                                        type="checkbox"
                                        id="flexSwitchCheckcustom"
                                        onChange={handleLanguageChange}
                                        checked={language === 'ta'}
                                        aria-label="flexSwitchCheckcustom"
                                    />
                                </div>
                            </div>
                            <NavLink style={{ textDecoration: 'none' }} to={'/start-a-project'}>
                                <div className="startbtn-bg">
                                    <button className="startbt">{t('header.Start a Project')}</button>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div id="progress-barss"></div>
                </nav>
            </header>
        </>
    )
}

export default Header;