import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs, Autoplay, Pagination } from 'swiper/modules';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
// import { Pagination } from 'swiper/modules';
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Home = () => {
    const { t } = useTranslation();
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [swiperRef, setSwiperRef] = useState(null);
    const location = useLocation();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [selectedService, setSelectedService] = useState('');
    const [comments, setProjectDetails] = useState('');

    const [isLoading, setIsLoading] = useState(false); // State variable to track loading state

    const isFormValid = name.trim() !== '' && email.trim() !== '' && selectedService.trim() !== '';

    const handleCheckboxChange = (service) => {
        setSelectedService(service);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isFormValid) {
            alert('Please fill in all required fields.');
            return;
        }

        setIsLoading(true);

        const formData = {
            name,
            email,
            selectedService,
            comments
        };

        try {
            const response = await fetch('https://contactapi.dltlabz.io/api/user/scheduleAppointment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                // console.log('Form submitted successfully');
                toast.success(`Thanks for reaching out. We'll be back in touch soon.!`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000, // Auto close the toast after 3000ms (3 seconds)
                });
                // Simulate an asynchronous form submission with setTimeout
                setTimeout(() => {
                    // Handle form submission logic here (e.g., API request, etc.)
                    // After form submission, reset the form and loading state
                    setName('');
                    setEmail('');
                    setSelectedService('');
                    setProjectDetails(''); // Reset the form data after submission
                    setIsLoading(false);
                }, 2000);
            } else {
                console.error('Form submission failed');
            }
        } catch (error) {
            toast.error('Error submitting form', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000, // Auto close the toast after 3000ms (3 seconds)
            });
            // console.error('An error occurred:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const hash = location?.hash;
        if (hash) {
            const section = document.querySelector(hash);
            section?.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    }, []);

    return (
        <>
            <div className="mainsection">
                <img className="mainbgcircle" src="../assets/images/mainbgcircle.svg" alt="" />
                {/*  */}
                <Swiper
                    loop={true}
                    spaceBetween={10}
                    slidesPerView={1}
                    centeredSlides={false}
                    autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                    }}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[Autoplay, FreeMode, Navigation, Thumbs]}
                    className="mySwiper2"
                >
                    <SwiperSlide>
                        <div className="maintxt-section">
                            <h1 className="maintxt1">{t('Home.Innovative Client - Centric')}</h1>
                            <h1 className="maintxt1 maintext2">{t('Home.Mobile & Web')}</h1>
                            <h1 className="maintxt1">{t('Home.Solutions')}</h1>
                            <p className="maintxtsub">
                                {t('Home.With our full-service design to the development team')}
                            </p>
                            <ul className="mainse-bts">
                                {/* <li><NavLink to={'/Startaproject'}><button className="mainbts">Contact Us</button></NavLink></li> */}
                                <li>
                                    <NavLink to={'/start-a-project'}>
                                        <div className="mainbts-bg">
                                            <button className="mainbts">{t('Home.Start your Project')}</button>
                                        </div>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="maintxt-section">
                            <h1 className="maintxt1">{t('Home.Cutting-edge')}</h1>
                            <h1 className="maintxt1 maintext2">{t('Home.Blockchain Development')}</h1>
                            <h1 className="maintxt1">{t('header.Services')}</h1>
                            <p className="maintxtsub">
                                {t('Home.DLTLABZ hybrid blockchain technology lets businesses')}
                            </p>
                            <ul className="mainse-bts">
                                {/* <li><NavLink to={'/Startaproject'}><button className="mainbts">Contact Us</button></NavLink></li> */}
                                <li>
                                    <NavLink to={'/start-a-project'}>
                                        <div className="mainbts-bg">
                                            <button className="mainbts">{t('Home.Start your Project')}</button>
                                        </div>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="maintxt-section">
                            <h1 className="maintxt1">{t('Home.The Next Big thing!!')}</h1>
                            <h1 className="maintxt1 maintext2">{t('Home.Activate Web3')}</h1>
                            <h1 className="maintxt1">{t('Home.Collaborate worldwide')}</h1>
                            <p className="maintxtsub">
                                {t('Home.We offer a comprehensive design and development service')}
                            </p>
                            <ul className="mainse-bts">
                                {/* <li><NavLink to={'/Startaproject'}><button className="mainbts">Contact Us</button></NavLink></li> */}
                                <li>
                                    <NavLink to={'/start-a-project'}>
                                        <div className="mainbts-bg">
                                            <button className="mainbts">{t('Home.Start your Project')}</button>
                                        </div>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </SwiperSlide>
                </Swiper>

                <Swiper
                    onSwiper={setThumbsSwiper}
                    loop={true}
                    // spaceBetween={16}
                    // slidesPerView={3}
                    // freeMode={true}
                    // watchSlidesProgress={true}
                    // modules={[FreeMode, Navigation, Thumbs]}
                    // className="mySwiper"
                    direction={"horizontal"}
                    centeredSlides={false}
                    autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                        },
                    }}
                    modules={[Autoplay]}
                    className="mySwiper"
                    grabCursor={true}
                    freeMode={true}
                    spaceBetween={50}
                    scrollbar={{ draggable: true }}
                    dir="ltr"

                >
                    <SwiperSlide>
                        <div className="mainbox1">
                            <div className="mainbox1bg-sec">
                                <h1 className="mainboxt1">Mobile & Web</h1>
                                <p className="mainboxt2">
                                    {t('Home.With our full-service design to the development team, we help')}
                                </p>
                                {/* <p className="mainboxt2num">
                                    1
                                </p> */}
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="mainbox1">
                            <div className="mainbox1bg-sec mainbox1bg-sec3">
                                <h1 className="mainboxt1">{t('Home.Blockchain technology')}</h1>
                                <p className="mainboxt2">
                                    {t('Home.We assist businesses of all sizes in adopting decentralised')}
                                </p>
                                {/* <p className="mainboxt2num">
                                    2
                                </p> */}
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="mainbox1">
                            <div className="mainbox1bg-sec  mainbox1bg-sec2">
                                <h1 className="mainboxt1">WEB3</h1>
                                <p className="mainboxt2">
                                    {t('Home.We leverage cutting-edge Web-3 technology to provide')}
                                </p>
                                {/* <p className="mainboxt2num">
                                    3
                                </p> */}
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>

            <div className="section2">
                <div className="container">
                    <Swiper
                        spaceBetween={10}
                        rewind={true}
                        centeredSlides={false}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        breakpoints={{
                            410: {
                                slidesPerView: 3,
                            },
                            640: {
                                slidesPerView: 4,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 10,
                            },
                            1024: {
                                slidesPerView: 5,
                                spaceBetween: 60,
                            },
                        }}
                        navigation={false}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiperlogo"
                    >
                        <SwiperSlide><img className="projimg" src="../assets/images/logos/2.svg" alt="" /></SwiperSlide>
                        <SwiperSlide><img className="projimg" src="../assets/images/logos/3.svg" alt="" /></SwiperSlide>
                        <SwiperSlide><img className="projimg" src="../assets/images/logos/4.svg" alt="" /></SwiperSlide>
                        <SwiperSlide><img className="projimg" src="../assets/images/logos/6.svg" alt="" /></SwiperSlide>
                        <SwiperSlide><img className="projimg" src="../assets/images/logos/7.svg" alt="" /></SwiperSlide>
                        <SwiperSlide><img className="projimg" src="../assets/images/logos/8.svg" alt="" /></SwiperSlide>
                        <SwiperSlide><img className="projimg" src="../assets/images/logos/9.svg" alt="" /></SwiperSlide>
                        <SwiperSlide><img className="projimg" src="../assets/images/logos/10.svg" alt="" /></SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="section3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="section3-left">
                                {/* <h6 className="section-txt">Largest digital</h6>
                                <h6 className="section-txt">marketing</h6>
                                <h6 className="section-txt">conference</h6> */}
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="section3-rightmain">
                                <img className="section3-rightbg" src="../assets/images/section3-right.svg" alt="" />
                                <h1 className="section3right-head">DLT LABZ is a global services company founded in 2017</h1>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="section3right-sec2">
                                            <h2 className="section3right-head2">7 +</h2>
                                            <p className="section3right-subtxt">
                                                {t('Home.At DLT Labz, we have dedicated the past 7 years to harnessing')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="section3right-sec2">
                                            <h2 className="section3right-head2">20 +</h2>
                                            <p className="section3right-subtxt">
                                                {t('Home.These are just a glimpse of the remarkable')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <NavLink to={'/about-us'}>
                                <div className="section3right-btbg">
                                    <button className="section3right-bt">{t('Home.More About Us')}</button>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section4-main">
                <div className="container">
                    <h2 className="section4-head">DLT LABZ</h2>
                    <h2 className="section4-head">{t('Home.Range of our services')}</h2>

                    <div className="row">
                        <div className="col-md-3">
                            <div className="section4box-bord">
                                <div className="section4box-sec">
                                    <div className="section4box2">
                                        <h2 className="section4box-head">{t('Home.Information')}</h2>
                                        <h2 className="section4box-head">{t('Home.Technology')}</h2>
                                        <p className="section4box-txt">
                                            {t('Home.Energize your business with strategic support')}
                                        </p>
                                    </div>
                                    <ul className="section4box-u">
                                        <li><img className="section4box-l" src="../assets/images/section4/Icon.png" alt="" /></li>
                                        <li> <NavLink className="section4box-la" to={'/information-technology'}>{t('Home.Learn more')}</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="section4box-bord">
                                <div className="section4box-sec section4box-sec3">
                                    <div className="section4box2">
                                        <h2 className="section4box-head">{t('Home.Blockchain')}</h2>
                                        <h2 className="section4box-head">{t('Home.Technology')}</h2>
                                        <p className="section4box-txt">
                                            {t('Home.We can assist you with testing and deploying')}
                                        </p>
                                    </div>
                                    <ul className="section4box-u">
                                        <li><img className="section4box-l" src="../assets/images/section4/Icon.png" alt="" /></li>
                                        <li><NavLink className="section4box-la" to={'/blockchain-technology'}>{t('Home.Learn more')}</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="section4box-bord">
                                <div className="section4box-sec section4box-sec3">
                                    <div className="section4box2">
                                        <h2 className="section4box-head">{t('Home.Mobile')}</h2>
                                        <h2 className="section4box-head">{t('Home.Application')}</h2>
                                        <p className="section4box-txt">
                                            {t('Home.Mobile devices and apps are consistently evolving')}
                                        </p>
                                    </div>
                                    <ul className="section4box-u">
                                        <li><img className="section4box-l" src="../assets/images/section4/Icon.png" alt="" /></li>
                                        <li> <a className="section4box-la" href={'/mobile-application'}>{t('Home.Learn more')}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="section4box-bord">
                                <div className="section4box-sec section4box-sec2">
                                    <div className="section4box2">
                                        <h2 className="section4box-head">{t('Home.DevOps')}</h2>
                                        <h2 className="section4box-head">{t('Home.Solutions')}</h2>
                                        <p className="section4box-txt">
                                            DLT LABZ, which uses data programs and authentication to test areas of critical data is the first-of-its-kind full DevOps solution.
                                        </p>
                                    </div>
                                    <ul className="section4box-u">
                                        <li><img className="section4box-l" src="../assets/images/section4/Icon.png" alt="" /></li>
                                        <li> <a className="section4box-la" href={'/devops-solutions'}>{t('Home.Learn more')}</a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="section4box-bord">
                                <div className="section4box-sec">
                                    <div className="section4box2">
                                        <h2 className="section4box-head">NFT</h2>
                                        {/* <h6 className="section4box-head">Technology</h6> */}
                                        <p className="section4box-txt section4box-txt2">
                                            {t('Home.Non-fungible tokens')}
                                        </p>
                                    </div>
                                    <ul className="section4box-u">
                                        <li><img className="section4box-l" src="../assets/images/section4/Icon.png" alt="" /></li>
                                        <li> <a className="section4box-la" href={'/nft'}>{t('Home.Learn more')}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="section4box-bord">
                                <div className="section4box-sec section4box-sec2">
                                    <div className="section4box2">
                                        <h2 className="section4box-head">Metaverse</h2>
                                        {/* <h6 className="section4box-head">Solutions</h6> */}
                                        <p className="section4box-txt section4box-txt2">
                                            {t('Home.Build your metaverse project')}
                                        </p>
                                    </div>
                                    <ul className="section4box-u">
                                        <li><img className="section4box-l" src="../assets/images/section4/Icon.png" alt="" /></li>
                                        <li> <a className="section4box-la" href={'/metaverse'}>{t('Home.Learn more')}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="section4box-bord">
                                <div className="section4box-sec section4box-sec4">
                                    <div className="section4box2">
                                        <h2 className="section4box-head">{t('Home.Business')}</h2>
                                        <h2 className="section4box-head">{t('Home.Outsourcing')}</h2>
                                        <p className="section4box-txt">
                                            {t('Home.Business Outsourcing Services is a')}
                                        </p>
                                    </div>
                                    <ul className="section4box-u">
                                        <li><img className="section4box-l" src="../assets/images/section4/Icon.png" alt="" /></li>
                                        <li> <a className="section4box-la" href={'/business-outsourcing'}>{t('Home.Learn more')}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="section4box-bord">
                                <div className="section4box-sec section4box-sec8">
                                    <div className="section4box2 section4box21">
                                        <h2 className="section4box-head">{t('Home.Are you already?')}</h2>
                                        <h2 className="section4box-head">{t('Home.looking forward to')}</h2>
                                        <h2 className="section4box-head">{t('Home.starting your project?')}</h2>
                                    </div>
                                    <NavLink to={'/start-a-project'}><button className="startapro">{t('header.Start a Project')}</button></NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section5-main">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <h2 className="section5-head">{t('Home.Case')}</h2>
                            <h2 className="section5-head">{t('Home.Studies')}</h2>
                        </div>
                        <div className="col-md-5">
                            <p className="section5-txt">
                                {t('Home.Overall, a case study is a comprehensive')}
                            </p>
                        </div>
                    </div>
                    <div className="caseStudies-slide">
                        <div className="row">
                            <Swiper
                                direction={"horizontal"}
                                rewind={true}
                                onSwiper={setSwiperRef}
                                centeredSlides={false}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 15,
                                    },
                                    1024: {
                                        slidesPerView: 2,
                                        spaceBetween: 15,
                                    },
                                }}
                                navigation={true}
                                pagination={{
                                    // type: 'fraction',
                                }}
                                modules={[Autoplay, Pagination, Navigation]}
                                className="mySwiper"
                                grabCursor={true}
                                freeMode={true}
                                spaceBetween={20}
                                scrollbar={{ draggable: true }}
                                dir="ltr"
                            >
                                <SwiperSlide>
                                    <div>
                                        <a href={'/ACaseStudyonSmashitLive'} style={{ textDecoration: 'none' }}>
                                            <div className="caseStudies">
                                                <div className="row">
                                                    <div className="col-8">
                                                        <div className="casestudy-se">
                                                            <h3 className="casestudyslide-head">
                                                                #{t('Home.Mobile')}
                                                            </h3>
                                                            <h3 className="casestudyslide-head">
                                                                {t('Home.Application')}
                                                            </h3>
                                                            <p className="casestudyslide-txt">
                                                                {t('Home.In todays digital era, mobile applications')}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        {/* <h4>dsdsds</h4> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <ul className="section4box-u">
                                                <li><img className="section4box-l" src="../assets/images/section4/Icon.png" alt="" /></li>
                                                <li> <a className="section4box-la" href={'/app-case-study-smashitlive'}>{t('Home.Learn more')}</a></li>
                                            </ul>
                                        </a>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div>
                                        <a href={'/DevOpsCaseStudy'} style={{ textDecoration: 'none' }}>
                                            <div className="caseStudies">
                                                <div className="row">
                                                    <div className="col-10">
                                                        <div className="casestudy-se">
                                                            <h3 className="casestudyslide-head">
                                                                #DevOps
                                                            </h3>
                                                            <h3 className="casestudyslide-head">
                                                                {t('Home.Solutions')}
                                                            </h3>
                                                            <p className="casestudyslide-txt">
                                                                {t('Home.DevOps in overcoming operational challenges')}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        {/* <h4>dsdsds</h4> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <ul className="section4box-u">
                                                <li><img className="section4box-l" src="../assets/images/section4/Icon.png" alt="" /></li>
                                                <li> <a className="section4box-la" href={'/devops-case-study-coinovy'}>{t('Home.Learn more')}</a></li>
                                            </ul>
                                        </a>
                                    </div>

                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ProcessWork-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <h2 className="ProcessWork-head">{t('Home.Process')}</h2>
                            <h2 className="ProcessWork-head">{t('Home.Work')}</h2>
                        </div>
                        <div className="col-md-4">
                            <p className="ProcessWork-txt">
                                {t('Home.Crafting Client-Centric Mobile')}
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="ProcessWork-left">
                                <div className="d-flex align-items-start">
                                    <div className="nav flex-column nav-pills" style={{ width: '100%' }} id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <div className="nav-link active ProcessWork-bg" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                            <div className="processwork-mainbg">
                                                <h3 className="ProcessWork-boxhead">{t('Home.Strategy and Planning')}</h3>
                                                <p className="ProcessWork-boxtxt">{t('Home.Based on the insights gained from the')}</p>
                                            </div>
                                        </div>
                                        <div className="nav-link ProcessWork-bg" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                            <div className="processwork-mainbg">
                                                <h3 className="ProcessWork-boxhead">{t('Home.UI/UX Design & Development')}</h3>
                                                <p className="ProcessWork-boxtxt">{t('Home.Our expert designers craft visually')}</p>
                                            </div>
                                        </div>
                                        <div className="nav-link ProcessWork-bg" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                            <div className="processwork-mainbg">
                                                <h3 className="ProcessWork-boxhead">{t('Home.Testing, Deployment and Launch')}</h3>
                                                <p className="ProcessWork-boxtxt">{t('Home.We rigorously test each aspect')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="ProcessWork-right">
                                <div className="tab-content" id="v-pills-tabContent">
                                    <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                        <img className="ProcessWork-im" src="../assets/images/StrategyandPlanning.png" alt="" />
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                        <img className="ProcessWork-im" src="../assets/images/UIUXDesignDevelopment.png" alt="" />
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                        <img className="ProcessWork-im" src="../assets/images/TestingDeploymentandLaunch.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Short heading goes here */}
            <div className="section5-main2">
                <div className="container">
                    <h2 className="section5main2-head">The DLT LABZ BLOG</h2>
                    <p className="section5main2-txt">
                        {t('Home.Join us as we delve deep into the heart')}
                    </p>
                    <div className="section5main2-slide">
                        <div className="row">
                            <div className="col-md-4">
                                <NavLink style={{ textDecoration: 'none' }} to={'/Web3'}>
                                    <div className="section5main2-slide2">
                                        <img className="section5main2slide-im" src="../assets/images/blog/blog1.webp" alt="" />
                                        <h2 className="section5main2slide-txt2">{t('Home.What is Web3? A Friendly Introduction')}</h2>
                                        <p className="section5main2slide-txt3"> {t('Home.Web3 has become more than a concept but a')}</p>
                                        <p className="read-more">{t('Home.Read More...')}</p>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-md-4">
                                <NavLink style={{ textDecoration: 'none' }} to={'/BlockchainTechnology'}>
                                    <div className="section5main2-slide2">
                                        <img className="section5main2slide-im" src="../assets/images/blog/blog3.webp" alt="" />
                                        <h2 className="section5main2slide-txt2">{t('Home.DLT LABZ Expertise the Blockchain Technology')}</h2>
                                        <p className="section5main2slide-txt3">{t('Home.At DLT LABZ, we are a socially Intellectual')} </p>
                                        <p className="read-more">{t('Home.Read More...')}</p>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-md-4">
                                <NavLink style={{ textDecoration: 'none' }} to={'/WebAssembly'}>
                                    <div className="section5main2-slide2">
                                        <img className="section5main2slide-im" src="../assets/images/blog/blog2.webp" alt="" />
                                        <h2 className="section5main2slide-txt2">{t('Home.An Introduction to Web Assembly')}</h2>
                                        <p className="section5main2slide-txt3"> {t('Home.Today, web browsers are an indispensable')}</p>
                                        <p className="read-more">{t('Home.Read More...')}</p>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="review-section">
                <div className="container">
                    <h2 className="reviewsection-head">{t('Home.We work hard to')}</h2>
                    <h2 className="reviewsection-head">{t('Home.satisfy our clients')}</h2>
                    <div className="review-slidesec">
                        <Swiper
                            direction={"horizontal"}
                            onSwiper={setSwiperRef}
                            centeredSlides={false}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 2,
                                    spaceBetween: 40,
                                },
                            }}
                            navigation={true}
                            pagination={{
                                // type: 'fraction',
                            }}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="mySwiper"
                            grabCursor={true}
                            freeMode={true}
                            spaceBetween={40}
                            scrollbar={{ draggable: true }}
                            dir="ltr"
                        >
                            <SwiperSlide>
                                <div>
                                    <img className="starim" src="../assets/images/ratingimg.svg" alt="" />
                                    <p className="review-txt"> "{t('Home.They understood our vision and transformed it into a')}" </p>
                                    <div className="media custm-media">
                                        <div className="media-left">
                                            <img src="../assets/images/profile.webp" className="media-object clientim" alt="" />
                                        </div>
                                        <div className="media-body">
                                            <h2 className="clientName">{t('Home.William Taylor')}</h2>
                                            {/* <p className="clientdec">Lorem ipsum.</p> */}
                                        </div>
                                    </div>

                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div>
                                    <img className="starim" src="../assets/images/ratingimg.svg" alt="error" />
                                    <p className="review-txt">"{t('Home.Weve worked with several technology')}" </p>
                                    <div className="media custm-media">
                                        <div className="media-left">
                                            <img src="../assets/images/profile.webp" className="media-object clientim" alt="" />
                                        </div>
                                        <div className="media-body">
                                            <h2 className="clientName">{t('Home.Sophia White')}</h2>
                                            {/* <p className="clientdec">Lorem ipsum.</p> */}
                                        </div>
                                    </div>

                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div>
                                    <img className="starim" src="../assets/images/ratingimg.svg" alt="" />
                                    <p className="review-txt">"{t('Home.Collaborating with DLT LABZ was a')}" </p>
                                    <div className="media custm-media">
                                        <div className="media-left">
                                            <img src="../assets/images/profile.webp" className="media-object clientim" alt="" />
                                        </div>
                                        <div className="media-body">
                                            <h2 className="clientName">{t('Home.Matthew Smith')}</h2>
                                            {/* <p className="clientdec">Lorem ipsum.</p> */}
                                        </div>
                                    </div>

                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div>
                                    <img className="starim" src="../assets/images/ratingimg.svg" alt="" />
                                    <p className="review-txt">“{t('Home.Our vision to bring art and culture')}”</p>
                                    <div className="media custm-media">
                                        <div className="media-left">
                                            <img src="../assets/images/profile.webp" className="media-object clientim" alt="" />
                                        </div>
                                        <div className="media-body">
                                            <h2 className="clientName">{t('Home.Andrew Johnson')}</h2>
                                            {/* <p className="clientdec">Lorem ipsum.</p> */}
                                        </div>
                                    </div>

                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div>
                                    <img className="starim" src="../assets/images/ratingimg.svg" alt="" />
                                    <p className="review-txt">“{t('Home.Bringing education into the metaverse')}”</p>
                                    <div className="media custm-media">
                                        <div className="media-left">
                                            <img src="../assets/images/profile.webp" className="media-object clientim" alt="" />
                                        </div>
                                        <div className="media-body">
                                            <h2 className="clientName">{t('Home.Emma Davis')}</h2>
                                            {/* <p className="clientdec">Lorem ipsum.</p> */}
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div>
                                    <img className="starim" src="../assets/images/ratingimg.svg" alt="" />
                                    <p className="review-txt">“{t('Home.Their technical prowess, combined')}”</p>
                                    <div className="media custm-media">
                                        <div className="media-left">
                                            <img src="../assets/images/profile.webp" className="media-object clientim" alt="" />
                                        </div>
                                        <div className="media-body">
                                            <h2 className="clientName">{t('Home.Olivia Jackson')}</h2>
                                            {/* <p className="clientdec">Lorem ipsum.</p> */}
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div>
                                    <img className="starim" src="../assets/images/ratingimg.svg" alt="" />
                                    <p className="review-txt">“{t('Home.Their commitment to understanding')}”</p>
                                    <div className="media custm-media">
                                        <div className="media-left">
                                            <img src="../assets/images/profile.webp" className="media-object clientim" alt="" />
                                        </div>
                                        <div className="media-body">
                                            <h2 className="clientName">{t('Home.Ava Garcia')}</h2>
                                            {/* <p className="clientdec">Lorem ipsum.</p> */}
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>

            <div className="section6-faq">
                <div className="container">
                    <h2 className="section6-head">{t('Home.FAQ')}</h2>
                    <h2 className="section6-head">{t('Home.Find out what matters to you!')}</h2>
                    <div>
                        <div className="wrapper">
                            <div className="accordion accordion-flush" id="myAccordion">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne1">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne"> <span>01 </span>{t('Home.What services does your IT and software development company provide?')}</button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('Home.Our company offers a comprehensive range')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne2">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo"> <span>02 </span>{t('Home.How experienced is your team of developers?')}</button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('Home.Our team comprises highly skilled')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne3">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree"> <span>03 </span>{t('Home.What is the software development process')}</button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('Home.We adhere to a structured software')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne4">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour"> <span>04 </span>{t('Home.How long does it typically take to')}</button>
                                    </h2>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('Home.The timeline for software development')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne5">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive"> <span>05 </span>{t('Home.Can you provide examples of previous')}</button>
                                    </h2>
                                    <div id="flush-collapseFive" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className=""> {t('Home.Absolutely! Our portfolio showcases a variety')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne6">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix"> <span>06 </span>{t('Home.How do you ensure the security')} </button>
                                    </h2>
                                    <div id="flush-collapseSix" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('Home.Security is a top priority for us')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne7">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven"> <span>07 </span>{t('Home.How do I get started with your company on a')}</button>
                                    </h2>
                                    <div id="flush-collapseSeven" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('Home.Getting started is easy. Simply reach')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="section7">
                <div className="container">
                    <h2 className="section7-head">{t('Home.Lets talk')}</h2>
                    <h2 className="section7-head">{t('Home.about your project!')}</h2>
                    <div className="section7-main">
                        <div className="section7-bgim">
                            <p className="getin">* {t('Home.Get in Touch')}</p>
                            <h2 className="getin-head">
                                {t('Home.Schedule an Appointment')}
                            </h2>
                            <form onSubmit={handleSubmit}>
                                <div className="row g-3 getin-sec">
                                    <div className="col-auto">
                                        <label className="col-form-label myname">{t('Home.Hey, my name is')}</label>
                                    </div>
                                    <div className="col-auto">
                                        <input
                                            type="text"
                                            className="form-control mynameinp"
                                            placeholder={t('Home.Enter your name')}
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            aria-labelledby="text"
                                        />
                                    </div>
                                    <div className="col-auto">
                                        <label className="col-form-label myname">{t('Home.and my email is')}</label>
                                    </div>
                                    <div className="col-auto">
                                        <input
                                            type="text"
                                            className="form-control mynameinp"
                                            placeholder={t('Home.Enter your Email')}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            aria-labelledby="text"
                                        />
                                    </div>
                                </div>
                                <div className="row getin-sec" style={{ alignItems: 'center' }}>
                                    <div className="col-md-2">
                                        <label className="col-form-label myname">{t('Home.Iam looking for a')}</label>
                                    </div>
                                    <div onChange={(e) => handleCheckboxChange(e.target.value)}>
                                        <div className="col-md-2">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input inpselect"
                                                    type="radio"
                                                    value="Web Solutions"
                                                    checked={selectedService === 'Web Solutions'}
                                                    aria-labelledby="radio"
                                                    id="websolutions"
                                                />
                                                <label for="websolutions" className="form-check-label checktxt">
                                                    {t('Home.Web Solutions')}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input inpselect"
                                                    type="radio"
                                                    value="Mobile Solutions"
                                                    checked={selectedService === 'Mobile Solutions'}
                                                    id="mobilesolutions"
                                                />
                                                <label for="mobilesolutions" className="form-check-label checktxt">
                                                    {t('Home.Mobile Solutions')}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input inpselect"
                                                    type="radio"
                                                    value="Both"
                                                    checked={selectedService === 'Both'}
                                                    aria-labelledby="radio"
                                                    id="bothsolutions"
                                                />
                                                <label for="bothsolutions" className="form-check-label checktxt">
                                                {t('Home.Both')}
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row g-3 getin-sec">
                                    <div className="col-md-5">
                                        <label className="col-form-label myname">{t('Home.Here are more details on the project (optional)')}:</label>
                                    </div>
                                    <div className="col-md-7">
                                        <input
                                            type="text"
                                            className="form-control mynameinp"
                                            placeholder={t('Home.Enter project details')}
                                            value={comments}
                                            onChange={(e) => setProjectDetails(e.target.value)}
                                            aria-labelledby="text"
                                        />
                                    </div>
                                </div>
                                <ul className="getin-btnsec">
                                    <li>
                                        <button
                                            type="submit"
                                            className="getin-bt1"
                                            disabled={!isFormValid || isLoading}
                                        >
                                            {isLoading ? t('Home.Sending...') : t('Home.Send enquiry')}
                                        </button>
                                        <ToastContainer />
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default Home;