import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useTranslation } from "react-i18next";


const Metaverse = () => {
    const { t } = useTranslation();
    const [swiperRef, setSwiperRef] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const hash = location?.hash;
        if (hash) {
            const section = document.querySelector(hash);
            section?.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    });

    // let appendNumber = 4;
    // let prependNumber = 1;

    return (
        <>
            <div className="mainsection">
                <img className="mainbgcircle" src="../assets/images/mainbgcircle.svg" alt="error" />
                <img className="Metaverse1" src="../assets/images/Metaverse/1.svg" alt="error" />
                <img className="Metaverse2" src="../assets/images/Metaverse/2.svg" alt="error" />
                <img className="Metaverse3" src="../assets/images/Metaverse/3.svg" alt="error" />
                {/*  */}
                <div className="maintxt-section">
                    <h1 className="maintxt1">{t('Metaverse.Welcome to the')}</h1>
                    <h1 className="maintxt1 maintext2">{t('Metaverse.Metaverse')}</h1>
                    <h1 className="maintxt1">{t('Metaverse.A New Reality Unveiled')}</h1>
                    <p className="maintxtsub">
                        {t('Metaverse.We offer a comprehensive')}
                    </p>
                    <ul className="mainse-bts">
                        <NavLink to={'/Startaproject'}>
                            <div className="mainbts-bg">
                                <button className="mainbts mainbts-metaverse" >{t('Metaverse.Start your Project')}</button>
                            </div>
                        </NavLink>
                    </ul>
                </div>
            </div>

            <div className="visionandmission-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="vision-section">
                                <h6 className="vision-head">{t('information-technology.Our Vision')}</h6>
                                <img className="vision-imin" src="../assets/images/Vision.svg" alt="error" />
                                <p className="vision-txt">
                                    {t('information-technology.We aspire to be leaders in business')}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="vision-section mission-section">
                                <h6 className="vision-head">{t('information-technology.Our Mission')}</h6>
                                <img className="vision-imin Mission-imin" src="../assets/images/Mission.svg" alt="error" />
                                <p className="vision-txt">
                                    {t('information-technology.Give modern and future-looking')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Metaverse-section3">
                <div className="container">
                    <h2 className="Metaversesection3-head">{t('Metaverse.Build your own Metaverse')}</h2>
                    <h2 className="Metaversesection3-head">{t('Metaverse.platform with us')}</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="metaverse-left">
                                <ul className="mleft-lis">
                                    <li>
                                        <p className="mleft-txt"> <span><img className="mleft-im" src="../assets/images/greenandwhite.svg" alt="error" /></span>{t('Metaverse.Metaverse NFT marketplace development')}</p>
                                    </li>
                                    <li>
                                        <p className="mleft-txt"><span><img className="mleft-im" src="../assets/images/greenandwhite.svg" alt="error" /></span>{t('Metaverse.Metaverse NFT development')}</p>
                                    </li>
                                    <li>
                                        <p className="mleft-txt"><span><img className="mleft-im" src="../assets/images/greenandwhite.svg" alt="error" /></span>{t('Metaverse.Metaverse e-commerce development')}</p>
                                    </li>
                                    <li>
                                        <p className="mleft-txt"><span><img className="mleft-im" src="../assets/images/greenandwhite.svg" alt="error" /></span>{t('Metaverse.Metaverse social media development')}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="metaverse-left metaverse-right">
                                <p className="mright-txt">
                                    {t('Metaverse.Creating your own Metaverse project')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <NavLink to={'/Startaproject'}><button className="Metaversesection3-bt">{t('Metaverse.Start your Project')}</button></NavLink>
                </div>
            </div>
            <div className="projects-section">
                {/* <img className="projectssection-bg" src="../assets/images/projects-section-bg.svg" alt="error" /> */}
                <div className="container">
                    <div className="projects-main">
                        <h6 className="projects-txt">{t('information-technology.Some treasures')}</h6>
                        <div className="row">
                            <div className="col-md-4">
                                <NavLink to={"https://sanjubaba.io/home"} target="_blank" style={{ cursor: 'pointer', textDecoration: 'none', }}>
                                    <h6 className="project-name">Sanjubaba</h6>
                                    <img className="projectim" src="../assets/images/sanjubaba.png" alt="" />
                                </NavLink>
                            </div>
                            <div className="col-md-4">
                                <NavLink to={"https://www.starmetaverse.io"} target="_blank" style={{ cursor: 'pointer', textDecoration: 'none', }}>
                                    <h6 className="project-name">Star Metaverse</h6>
                                    <img className="projectim" src="../assets/images/starmetaverse.png" alt="" />
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section6-faq">
                <div className="container">
                    <h2 className="section6-head">{t('Metaverse.FAQ')}</h2>
                    <h2 className="section6-head">{t('Metaverse.Find out what matters to you!')}</h2>
                    <div>
                        <div className="wrapper">
                            <div className="accordion accordion-flush" id="myAccordion">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne"> <span>01 </span>{t('Metaverse.What is a Metaverse Project?')}</button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('Metaverse.A Metaverse project involves the creation')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo"> <span>02 </span>{t('Metaverse.What are the key components of a Metaverse?')}</button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('Metaverse.A Metaverse comprises elements such')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree"> <span>03 </span>{t('Metaverse.How can a Metaverse project benefit my business or brand?')} </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('Metaverse.A Metaverse project can enhance brand')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour"> <span>04 </span>{t('Metaverse.How is blockchain technology integrated into a Metaverse project?')}</button>
                                    </h2>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('Metaverse.Blockchain technology is integrated to')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive"> <span>05 </span>{t('Metaverse.What role do avatars play in a Metaverse project?')}</button>
                                    </h2>
                                    <div id="flush-collapseFive" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('Metaverse.Avatars serve as digital representations')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix"> <span>06 </span>{t('Metaverse.Is a Metaverse project suitable for my industry?')}</button>
                                    </h2>
                                    <div id="flush-collapseSix" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('Metaverse.Metaverse projects have applicability across')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven"> <span>07 </span>{t('Metaverse.What is the development process for a Metaverse project?')}</button>
                                    </h2>
                                    <div id="flush-collapseSeven" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('Metaverse.The development process involves concept')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight"> <span>08 </span>{t('Metaverse.How do I get started with developing my Metaverse project?')}</button>
                                    </h2>
                                    <div id="flush-collapseEight" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">
                                                    {t('Metaverse.To begin, reach out to us through our contact page')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default Metaverse;