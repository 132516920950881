import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

const BlogThree = () => {
    const location = useLocation();

    useEffect(() => {
        const hash = location?.hash;
        if (hash) {
            const section = document.querySelector(hash);
            section?.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    });

    return (
        <>
            <div className="blog-mainsection">
                <img className="blog-blogbgi" src="../assets/images/blogbgi.png" alt="" />
                {/* <img className="blog-blogbgi2" src="../assets/images/blogbgi2.png" alt="" /> */}
                <div className="container">
                    <div className="blogtitle-section">
                        <h2 className="blog-title">DLT LABZ Expertise the Blockchain Technology</h2>
                        <NavLink className="blog-back" to={'/'}> <img className="blog-backim" src="../assets/images/left-arrow-blog.svg" alt="" /></NavLink>
                    </div>
                    <div className="blog-bgim">
                        <img className="" src="../assets/images/blog/blog3-1.png" alt="" />
                    </div>
                </div>

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-9">
                            <div className="blogcontent-section">
                                <h6 className="blog-subhead">Why DLTLABZ</h6>
                                <p className="blog-subtxt">At DLTLABZ, we are a socially Intellectual, Innovative community. DLT LABZ is informally intelligent community of inventive individuals who are inquisitive about why people do what they do.</p>
                                <p className="blog-subtxt">DLT LABZ responsible to provides an exceptional quality of services and sustain professionalism. By choosing DLT LABZ you will find a one-stop target for all kind of web development services and advertising solutions. We are specialists and concentrates in application development, website design, digital marketing, E-commerce services, SEO, content management with marketing.</p>
                                <p className="blog-subtxt">At DLT LABZ, We are having a skilled marketing team and responsive support team for smooth maintenance and services, best eminence and faithfulness to timelines.</p>

                                {/* <img className="blogim2" src="../assets/images/blogim2.png" alt="" />
                                <p className="blog-subtxt blog-subtxt2">Image caption goes here</p> */}

                                {/* <h6 className="blog-head3">Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.</h6>
                                <p className="blog-subtxt">Collaboratively deploy intuitive partnerships whereas customized e-markets. Energistically maintain performance based strategic theme areas whereas just in time methodologies. Phosfluorescently drive functionalized intellectual capital and.</p>
                                <p className="blog-subtxt3">"Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus."</p>
                                <p className="blog-subtxt">Tristique odio senectus nam posuere ornare leo metus, ultricies. Blandit duis ultricies vulputate morbi feugiat cras placerat elit. Aliquam tellus lorem sed ac. Montes, sed mattis pellentesque suscipit accumsan. Cursus viverra aenean magna risus elementum faucibus molestie pellentesque. Arcu ultricies sed mauris vestibulum.</p> */}
                                <h6 className="blog-subhead">DLT LABZ Growth Hub </h6>
                                <p className="blog-subtxt">The DLT LABZ Growth Hub is here to help your business maximize its potential. From one-to-one assistance and backing to a variety of expert-led events and online self-serve resources; there’s help for developing companies of all shapes and sizes. Whether it’s technology, business process management, and consulting services like Information Technology, Blockchain Technology, Business Outsourcing, Digital Currency Exchange Software, Security Services, DevOps Solutions, Mobile Applications, our experts are on-hand to consult, connect and collaborate. Dependent on the growth phase of your business, various possibilities will be accessible to you: All DLT LABZ businesses are able to access our online business support, attend our networking events and use The Growth Hub space. The next tier (access to our Insight, Highlight and Spotlight business growth events) will be open to businesses that meet the eligibility criteria. At an advanced level, our in-house Business Guides and Business Directors are on-hand to offer personalized support to businesses that show adequate growth and high growth respectively. Therefore, an early-stage business can access each level of support in turn; as it grows and meets the criteria for the next. Business support can be accessed remotely via our website, via telephone, or in-person whether at your place of work or one of our Growth Hubs around Hyderabad. </p>
                                <p className="blog-subtxt">The Growth Hub’s widespread digital and folks’ network is here to assist every business that’s seeking support to grow.</p>

                                <h6 className="blog-subhead">DLT LABZ Sponsorship</h6>
                                <p className="blog-subtxt">DLT LABZ follows the importance of business and industry working with sponsors to create and benefit from new business opportunities and how to foster these relationships. </p>
                                <p className="blog-subtxt">DLT LABZ invites sponsorship from businesses that want to benefit from growing together with DLT LABZ. Sponsorship opportunities from corporate businesses are open to benefit in sectors such as technology, business process management and consulting services like Information Technology, Blockchain Technology, and Business Outsourcing, Digital Currency Exchange Software, Security Services, DevOps Solutions, Mobile Applications. Sponsorship also offers a host of assistances and acquaintance amongst range of industry tech leaders, and the Research Centres community. At DLT LABZ we make sure that your businesses achieve the desired benefits from the investment made by sponsorship. Sponsors can also benefit from the growth of Business built on business-research collaboration and engagement. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogThree;