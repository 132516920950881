import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useTranslation } from "react-i18next";

const InformationTechnology = () => {
    const { t } = useTranslation();
    const [swiperRef, setSwiperRef] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const hash = location?.hash;
        if (hash) {
            const section = document.querySelector(hash);
            section?.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    });
    return (
        <>
            <div className="mainsection">
                <img className="mainbgcircle" src="../assets/images/mainbgcircle.svg" alt="error" />
                {/*  */}
                <div className="maintxt-section">
                    <h1 className="maintxt1">{t('information-technology.Welcome to the')}</h1>
                    <h1 className="maintxt1 maintext2">{t('information-technology.Information Technology')}</h1>
                    <p className="maintxtsub">
                        {t('information-technology.Energize your business with strategic')}
                    </p>
                    <ul className="mainse-bts">
                        <li>
                            <NavLink to={'/Startaproject'}>
                                <div className="mainbts-bg">
                                    <button className="mainbts mainbts-metaverse" to={'/'}>{t('information-technology.Start a Project')}</button>
                                </div>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="secondsection-forall secondsection-informtexh">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="secondsectionforall-left secondsectionforall-devops">
                                <h2 className="section-mainhead">
                                    {t('information-technology.Delivering Innovative and Transformative Ideas')}
                                </h2>
                                <p className="section-maintxt section-maintxtit">
                                    {t('information-technology.Our company manages a wide range')}
                                </p>
                                <p className="section-maintxt section-maintxtit">
                                    {t('information-technology.We help you target the relevant')}
                                </p>
                                <div className="metaverse-leftit">
                                    <ul className="mleft-lis">
                                        <li className="mleft-txt">
                                            {t('information-technology.Saving on the cost of providing IT services.')}
                                        </li>
                                        <li className="mleft-txt">
                                            {t('information-technology.Assures confidentiality, reliability')}
                                        </li>
                                        <li className="mleft-txt">
                                            {t('information-technology.A strong and protective IT')}
                                        </li>
                                        <li className="mleft-txt">
                                            {t('information-technology.Confident mobile and remote access to suitable resources.')}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <img className="section-mainim section-mainim-infrmtexh" src="../assets/images/it789.png" alt="dlt labz services information technology" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="visionandmission-section visionandmission-section2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="vision-section">
                                <h6 className="vision-head">{t('information-technology.Our Vision')}</h6>
                                <img className="vision-imin" src="../assets/images/Vision.svg" alt="error" />
                                <p className="vision-txt">
                                    {t('information-technology.We aspire to be leaders in business')}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="vision-section mission-section">
                                <h6 className="vision-head">{t('information-technology.Our Mission')}</h6>
                                <img className="vision-imin Mission-imin" src="../assets/images/Mission.svg" alt="error" />
                                <p className="vision-txt">
                                    {t('information-technology.Give modern and future-looking')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="projects-section projects-section2">
                {/* <img className="projectssection-bg" src="../assets/images/projects-section-bg.svg" alt="error" /> */}
                <div className="container">
                    <div className="projects-main">
                        <h6 className="projects-txt">{t('information-technology.Some treasures')}</h6>
                        <div className="row">
                            <div className="col-md-4">
                                <NavLink to={"https://devotiontourism.com"} target="_blank" style={{ cursor: 'pointer', textDecoration: 'none', }}>
                                    <h6 className="project-name">Devotion Tourism</h6>
                                    <img className="projectim" src="../assets/images/devotion.png" alt="error" />
                                </NavLink>
                            </div>
                            <div className="col-md-4">
                                <NavLink to={"https://www.coinlancer.com/home"} style={{ cursor: 'pointer', textDecoration: 'none', }}>
                                    <h6 className="project-name">Coinlancer</h6>
                                    <img className="projectim" src="../assets/images/coinlancer.png" alt="error" />
                                </NavLink>
                            </div>
                            <div className="col-md-4">
                                <NavLink to={"https://appadvice.com/app/vzero-experience-parking/1560777819.amp"} style={{ cursor: 'pointer', textDecoration: 'none', }}>
                                    <h6 className="project-name">V Zero</h6>
                                    <img className="projectim" src="../assets/images/vzero.png" alt="error" />
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section6-faq">
                <div className="container">
                    <h2 className="section6-head">{t('information-technology.FAQ')}</h2>
                    <h2 className="section6-head">{t('information-technology.Find out what matters to you!')}</h2>
                    <div>
                        <div className="wrapper">
                            <div className="accordion accordion-flush" id="myAccordion">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne"> <span>01 </span>{t('information-technology.What services does your IT and software development')} </button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('information-technology.Our company offers a comprehensive range')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo"> <span>02 </span>{t('information-technology.How experienced is your team of developers?')}</button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('information-technology.Our team comprises highly skilled and experienced software')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree"> <span>03 </span>{t('information-technology.What is the software development process your company follows?')}</button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('information-technology.We adhere to a structured software development')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour"> <span>04 </span>{t('information-technology.How long does it typically take to develop a software application?')}</button>
                                    </h2>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('information-technology.The timeline for software development varies')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive"> <span>05 </span>{t('information-technology.Can you provide examples of previous projects your company has worked on?')}</button>
                                    </h2>
                                    <div id="flush-collapseFive" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('information-technology.Absolutely! Our portfolio showcases a variety')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix"> <span>06 </span>{t('information-technology.How do you ensure the security of the software')} </button>
                                    </h2>
                                    <div id="flush-collapseSix" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('information-technology.Security is a top priority for us. We')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseseven"> <span>07 </span>{t('information-technology.How do I get started with your company on a software development project?')}</button>
                                    </h2>
                                    <div id="flush-collapseSeven" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                        <div className="accordion-body">
                                            <div className="custm-line">
                                                <p className="">{t('information-technology.Getting started is easy. Simply reach out')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default InformationTechnology;