// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslations from './Components/translations/en.json'; // Import your translation files
import taTranslations from './Components/translations/ta.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: enTranslations,
            },
            ta: {
                translation: taTranslations,
            },
        },
        fallbackLng: 'en', // Default language
        debug: true, // Enable debug output
        interpolation: {
            escapeValue: false, // React already escapes values
        },
    });

export default i18n;
